import { Button } from "@/UI/Button";
import { classNames } from "@/utils/helpers/classNames";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { GlobeIcon, MapPinnedIcon } from "@/assets";
import { JobFormSchema } from "@/utils/formDefinitions/jobManagementService/general";
import { Location } from "@/types/jobManagementService/general";
import { Modal } from "@/UI/Modal";
import { UseFormSetValue } from "react-hook-form";
import {
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import GooglePin from "@/assets/GooglePin";
import "./customLocation.css";
import { MAP_CENTER, NORTH_AMERICA_BOUNDS } from "@/utils/helpers/map";

type Props = {
  jobFormInputLocation: Location | string | undefined;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setValue: UseFormSetValue<JobFormSchema>;
};

type MapType = "roadmap" | "satellite" | "terrain" | "hybrid";

const CustomLocation: FC<Props> = ({
  jobFormInputLocation = "",
  open,
  setOpen,
  setValue,
}) => {
  const [newLocation, setNewLocation] = useState<Location>();
  const [mapType, setMapType] = useState<MapType>("satellite");

  const mapRef = useRef<google.maps.Map | null>(null);

  let autocompleteListener: google.maps.MapsEventListener;
  let mapListener: google.maps.MapsEventListener;

  const input = window.rb_map?.inputWrapper2;
  const map = window.rb_map?.mapWrapper;

  let pinWrapper: HTMLDivElement;

  const onTypeChange = (type: MapType) => {
    mapRef.current?.setMapTypeId(type);
    setMapType(type);
  };

  const onZoomChange = (zoomType: "plus" | "minus") => {
    const currZoom = mapRef.current?.getZoom();

    if (currZoom) {
      if (zoomType === "minus") {
        mapRef.current?.setZoom(currZoom - 1);
      } else {
        mapRef.current?.setZoom(currZoom + 1);
      }
    }
  };

  const onSave = () => {
    if (newLocation) {
      const input = document.getElementById(
        "rb-input-instance-1"
      ) as HTMLInputElement;

      if (input) {
        input.value = newLocation.name;
      }
      setValue("location", newLocation);
    }

    setOpen(false);
  };

  const onBeforeMount = () => {
    const inputWrapper = document.getElementById(
      "job-form-custom-location-autocomplete-wrapper"
    ) as HTMLDivElement;

    const mapWrapper = document.getElementById(
      "rb-job-form-map-wrapper"
    ) as HTMLDivElement;

    const autocomplete = window.rb_map?.autocomplete_instace_2;
    const googleMap = window.rb_map?.map_instance;
    const marker = window.rb_map?.marker_instance;
    const geoCoder = window.rb_map?.geocoding_instance;

    if (autocomplete && inputWrapper && input) {
      input.placeholder = "Select a Location";
      input.value =
        typeof jobFormInputLocation === "string"
          ? ""
          : jobFormInputLocation.name;

      autocomplete.setOptions({
        bounds: NORTH_AMERICA_BOUNDS,
        strictBounds: true,
        fields: ["formatted_address", "geometry"],
      });

      autocompleteListener = autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (marker && place.geometry?.location) {
          marker.position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
        }

        if (googleMap && place.geometry?.location) {
          googleMap.panTo({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }

        if (place.geometry?.location) {
          setNewLocation({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            name: place.formatted_address || "",
          });
        }
      });

      inputWrapper.appendChild(input);
    }

    if (marker && googleMap) {
      pinWrapper = document.createElement("div");
      pinWrapper.innerHTML = GooglePin;
      marker.map = googleMap;
      marker.content = pinWrapper;

      if (typeof jobFormInputLocation !== "string") {
        marker.position = {
          lat: jobFormInputLocation.lat,
          lng: jobFormInputLocation.lng,
        };
      }
    }

    if (googleMap && map && mapWrapper) {
      googleMap.setOptions({
        center: MAP_CENTER,
        restriction: {
          latLngBounds: NORTH_AMERICA_BOUNDS,
          strictBounds: true,
        },
        zoom: 3,
        mapId: "465a375eb871e19c",
        clickableIcons: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeId: "satellite",
      });

      mapListener = googleMap.addListener(
        "click",
        async (mapMouseEvent: google.maps.MapMouseEvent) => {
          if (mapMouseEvent.latLng && marker) {
            marker.position = mapMouseEvent.latLng;
            googleMap.panTo(mapMouseEvent.latLng);

            if (geoCoder) {
              const geocodadeLocation = await geoCoder.geocode({
                location: {
                  lat: mapMouseEvent.latLng.lat(),
                  lng: mapMouseEvent.latLng.lng(),
                },
              });

              setNewLocation({
                lat: geocodadeLocation.results[0].geometry.location.lat(),
                lng: geocodadeLocation.results[0].geometry.location.lng(),
                name: geocodadeLocation.results[0].formatted_address,
              });

              if (input) {
                input.value = geocodadeLocation.results[0].formatted_address;
              }
            }
          }
        }
      );

      if (typeof jobFormInputLocation !== "string") {
        googleMap.panTo({
          lat: jobFormInputLocation.lat,
          lng: jobFormInputLocation.lng,
        });
      }

      mapWrapper.appendChild(map);
      mapRef.current = googleMap;
    }
  };

  const onBeforeUnmount = () => {
    autocompleteListener?.remove();
    mapListener?.remove();

    if (input) {
      google.maps.event.removeListener(input);
      input.placeholder = "";
      input.value = "";
    }

    if (map) {
      google.maps.event.removeListener(map);
    }

    if (pinWrapper) {
      pinWrapper.remove();
    }
  };

  return (
    <Modal
      open={open}
      hideButtons
      beforeEnter={onBeforeMount}
      beforeLeave={onBeforeUnmount}
    >
      <header className="mb-8 flex flex-row items-end justify-between gap-20">
        <div>
          <h1 className="mb-4 text-4xl font-semibold">
            Select Jobsite Location
          </h1>
          <h2 className="text-base font-medium">
            Search for an address or choose a location by clicking on the map
          </h2>
        </div>

        <div className="relative flex grow flex-col">
          <div className="absolute inset-y-0 left-1 flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4 stroke-palette-secondaryBlack" />
          </div>

          <div id="job-form-custom-location-autocomplete-wrapper"></div>
        </div>
      </header>

      <div className="relative h-[472px] w-full">
        <div id="rb-job-form-map-wrapper" className="h-full w-full"></div>
        {/* map type controls --start */}
        <div className="absolute right-3 top-3 z-10 flex flex-col">
          <button
            className={classNames(
              "flex h-12 w-12 items-center justify-center rounded-t-lg",
              `${
                mapType === "satellite" ? "bg-theme-green-primary" : "bg-white"
              }`
            )}
            onClick={() => onTypeChange("satellite")}
          >
            <GlobeIcon
              opacity={mapType === "satellite" ? false : true}
              className={
                mapType === "satellite"
                  ? "bg-opacity-100 fill-white opacity-100"
                  : "fill-black"
              }
            />
          </button>

          <button
            className={classNames(
              "flex h-12 w-12 items-center justify-center rounded-b-lg",
              `${mapType === "roadmap" ? "bg-theme-green-primary" : "bg-white"}`
            )}
            onClick={() => onTypeChange("roadmap")}
          >
            <MapPinnedIcon
              opacity={mapType === "roadmap" ? false : true}
              className={
                mapType === "roadmap" ? "fill-white !opacity-100" : "fill-black"
              }
            />
          </button>
        </div>
        {/* map type controls --end */}

        {/* map zoom controls --start */}
        <div className="absolute right-3 top-[120px] z-10 flex flex-col divide-y">
          <button
            className={classNames(
              "flex h-12 w-12 items-center justify-center rounded-t-lg bg-white"
            )}
            onClick={() => onZoomChange("plus")}
          >
            <PlusIcon className="h-5 w-5 stroke-palette-grey stroke-[2px]" />
          </button>

          <button
            className={classNames(
              "flex h-12 w-12 items-center justify-center rounded-b-lg bg-white"
            )}
            onClick={() => onZoomChange("minus")}
          >
            <MinusIcon className="h-5 w-5 stroke-palette-grey stroke-[2px]" />
          </button>
        </div>
        {/* map zoom controls --end */}
      </div>

      <footer className="mt-4 flex justify-end gap-x-4">
        <Button
          label="Cancel"
          variant="secondary"
          className="rounded-md"
          onClick={() => {
            setOpen(false);
          }}
        />
        <Button
          className="rounded-md"
          label="Select Location"
          onClick={onSave}
          variant="primary"
        />
      </footer>
    </Modal>
  );
};

export { CustomLocation };
