import { JobForm } from "@/components/JobManagement/JobForm";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useUsers } from "@/lib/react-query/queryHooks/useUsers";

export default function JobPage() {
  const { data: useBranchesData } = useBranches();
  const { data: useDivisionsData } = useDivisions();
  const { data: useUsersData } = useUsers();

  return (
    <>
      <div className="w-[916px] bg-transparent">
        <div className="h-screen items-center overflow-y-auto">
          <h1 className="mb-6 mt-24 text-4xl font-semibold">Create Job</h1>
          <h2 className="mb-6 text-lg font-medium">Job Details</h2>
          <JobForm
            branches={useBranchesData?.data}
            divisions={useDivisionsData?.data}
            edit={false}
            job={undefined}
            users={useUsersData?.data}
          />
        </div>
      </div>
    </>
  );
}
