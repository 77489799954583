import { UserFormSchema } from "./NewUserForm";

export const newUserQueryMapper = (
  formData: UserFormSchema,
  accountId: string
) => {
  const authMethod =
    formData.authMethod.value === "Mobile & Web"
      ? "both"
      : formData.authMethod.value.toLowerCase();
  const branchIds = formData.branch.map((branch) => branch.value);
  const divisionIds = formData.division.map((division) => division.value);
  const occupations = formData.occupation.map((occupation) => occupation.value);
  const permissions = [
    ...(formData.mobileAccess
      ? ["timecard:user", "mobilehome:user", "mobile:user", "general:tracker"]
      : []),
    ...(formData.webAccess ? ["web:assetmap"] : []),
    ...(formData.trackerManagement ? ["mobile:provisioning"] : []),
    ...(formData.fullInventoryAccess ? ["mobileassets:user"] : []),
    ...(formData.webAccess && formData.jobManagement ? ["web:schedule"] : []),
    ...(formData.webAccess && formData.formsManagement
      ? ["timecard:admin"]
      : []),
    ...(formData.webAccess && formData.assetManagement
      ? ["web:assetcrud"]
      : []),
    ...(formData.webAccess && formData.userManagement ? ["web:usercrud"] : []),
  ];
  const phoneNumber = formData.cellPhone.replace(/\D/g, "");

  return {
    authMethod: authMethod as "web" | "mobile" | "both",
    email: formData.email.length ? formData.email : undefined,
    phoneNumber: phoneNumber.length ? "+1".concat(phoneNumber) : undefined,
    metadata: {
      accountId,
      branchIds,
      disabled: formData.disabled,
      divisionIds,
      firstName: formData.firstName,
      lastName: formData.lastName,
      occupations,
      permissions,
      veteran: undefined,
    },
  };
};
