const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2338 12.81C15.5287 10.8556 15.3151 8.19634 13.5931 6.47434C11.6273 4.50855 8.44014 4.50855 6.47434 6.47434C4.50855 8.44014 4.50855 11.6273 6.47434 13.5931C8.19634 15.3151 10.8556 15.5287 12.81 14.2338L17.5762 19L19 17.5762L14.2338 12.81ZM12.1694 7.8981C13.3488 9.07757 13.3488 10.9899 12.1694 12.1694C10.9899 13.3488 9.07757 13.3488 7.8981 12.1694C6.71862 10.9899 6.71862 9.07757 7.8981 7.8981C9.07757 6.71862 10.9899 6.71862 12.1694 7.8981Z"
        fill="#98999E"
      />
    </svg>
  );
};

export { SearchIcon };
