import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";
import {
  AssetGroup,
  Group,
} from "../../../types/assetInventory/groups/general";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface UseAssetGroupQueryParams {
  groupId: string;
}

export const useAssetGroup = (params: UseAssetGroupQueryParams) => {
  const { fetchWithAuth } = useAuthContext();
  const { groupId } = params;

  return useQuery<AssetGroup, Error, Group>({
    queryKey: [QUERY_KEYS.ASSET_GROUP, { groupId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assetgroups/""`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching asset group");
      }

      return apiRes.json();
    },
    enabled: !!groupId,
    select: (data) => {
      const group = data.assetGroups.find((group) => group.id === groupId);
      return group ?? ({} as Group);
    },
  });
};
