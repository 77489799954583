import { QUERY_KEYS } from "../constants";
import { queryClient } from "../general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface UseDeleteAssetGroupMutationParams {
  groupId: string;
}

export const useDeleteAssetGroup = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<void, Error, UseDeleteAssetGroupMutationParams>({
    mutationFn: async ({ groupId }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assetgroups/""/${groupId}`,
        method: "DELETE",
      });

      if (!apiRes.ok) {
        throw new Error(`Error deleting asset group`);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ASSET_GROUPS],
      });
    },
  });
};
