import { GroupFormSchema } from "@/components/AssetInventory/Groups/GroupForm";

export const assetDefinition = {
  assetName: "",
  assetId: "",
  quantity: "",
};

export const formDefaultValues = {
  metadata: [
    { metadataName: "", metadataUnitOfMeasure: "", metadataValue: "" },
  ],
  assetGroupName: "",
  assets: [{ assetId: "", assetName: "", quantity: "" }],
  accountId: "",
  assetGroupStatus: { id: "", value: "" },
  id: "",
};

export const assetGroupFormToEditQueryMapper = (formData: GroupFormSchema) => {
  return {
    assetGroupName: formData.assetGroupName,
    assetGroupStatus: formData.assetGroupStatus.value,
    assets: formData.assets,
    metadata: formData.metadata,
  };
};

export const assetGroupFormToCreateQueryMapper = (
  formData: GroupFormSchema
) => {
  return {
    assetGroupName: formData.assetGroupName,
    assetGroupStatus: formData.assetGroupStatus.value,
    assets: formData.assets,
    metadata: formData.metadata,
  };
};
