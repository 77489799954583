import { Job, UpdateJob } from "@/types/jobManagementService/general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseEditJobMutationParams {
  props: UpdateJob & { id: string };
}

export const useEditJob = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<Job, Error, UseEditJobMutationParams>({
    mutationFn: async ({ props }) => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/job/${props.id}`,
        method: "PATCH",
        body: JSON.stringify(props),
      });

      if (!apiRes.ok) {
        throw new Error(`Error editing Job`);
      }

      return apiRes.json();
    },
  });
};
