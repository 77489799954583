import { GroupAsset } from "../../../types/assetInventory/groups/general";
import { Metadata } from "@/types/assetInventory/metadata";
import { QUERY_KEYS } from "../constants";
import { queryClient } from "../general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface Props {
  assetGroupName: string;
  assetGroupStatus: string;
  assets: GroupAsset[];
  metadata: Metadata[];
}

interface UseCreateAssetGroupMutationParams {
  props: Props;
}

export const useCreateAssetGroup = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<void, Error, UseCreateAssetGroupMutationParams>({
    mutationFn: async ({ props }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assetgroups/""`,
        method: "POST",
        body: JSON.stringify(props),
      });

      if (!apiRes.ok) {
        throw new Error(`Error creating asset group`);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ASSET_GROUPS] });
    },
  });
};
