import { FC, useState } from "react";
import { MultiQuoteItemValue, RowField } from "@/types/forms/formEngine";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "@/UI/Button";
import { Divider } from "@/UI/Divider";
import { usePricebookItems } from "@/lib/react-query/queryHooks/usePricebookItems";
import { classNames } from "@/utils/helpers/classNames";
import { QuoteItemsModal } from "./QuoteItemsModal";
import { GqlPriceBookItem } from "@/lib/graphql/graphql";
import { ControlledInput } from "@/UI/Input";
import { ControlledTextArea } from "@/UI/TextArea";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { throttle } from "@/utils/helpers/throttle";
import { ArrowMoveDown, ArrowMoveUp, CopyIcon, TrashIcon } from "@/assets";
import { Transition } from "@headlessui/react";
import { MultiQuoteItemFieldHeaders } from "./MultiQuoteItemFieldHeaders";

type Props = {
  fieldItem: RowField;
};

export const EditMultiQuoteItemField: FC<Props> = ({ fieldItem }) => {
  const [quoteItemsModalOpen, setQuoteItemsModalOpen] =
    useState<boolean>(false);
  const { control, getValues, watch } = useFormContext();
  const {
    fields: quoteItems,
    append,
    remove,
    swap,
    insert,
  } = useFieldArray({
    control,
    name: fieldItem?.id ?? "",
  });
  const { data: pricebookItems } = usePricebookItems({
    options: { staleTime: 1000 * 60 * 5 },
  });

  const getPricebookItemById = (id: string) => {
    return pricebookItems?.data?.priceBookItems?.find(
      (item) => item?.id === id
    ) as GqlPriceBookItem;
  };
  const handleAddLineItems = (lineItems: GqlPriceBookItem[]) => {
    const appendItems = lineItems.map((lineItem) => ({
      pricebookItemId: lineItem.id,
      quantity: Number(1).toFixed(2),
      notes: "",
    }));
    append(appendItems);
    setQuoteItemsModalOpen(false);
  };
  const getAmount = (params: {
    quoteItem: MultiQuoteItemValue;
    pricebookItem: GqlPriceBookItem;
  }) => {
    if (!params?.quoteItem?.quantity || !params?.pricebookItem?.rate) return 0;
    return formatCurrency(
      Number(params.quoteItem.quantity) * Number(params.pricebookItem.rate)
    );
  };

  const [hoverButtonsTaskIndex, setHoverButtonsTaskIndex] = useState<
    number | undefined
  >();

  const throttledMouseEnter = throttle(
    (index: number) => setHoverButtonsTaskIndex(index),
    100
  );
  const [listRef] = useAutoAnimate<HTMLDivElement>();
  const getSubtotal = () => {
    const items = getValues(`${fieldItem.id}`);
    if (!Array.isArray(items)) return 0;
    const sum = items?.reduce((acc: any, quoteItem: any) => {
      const pricebookItem = getPricebookItemById(quoteItem.pricebookItemId);
      return (
        acc +
        Number(quoteItem?.quantity ?? 0) * Number(pricebookItem?.rate ?? 0)
      );
    }, 0);
    return sum as number;
  };

  const getTotal = () => {
    const subTotal = getSubtotal();
    const surcharge = getValues(`${fieldItem?.attributes?.surchargeId}`);
    const surchargeTotal = (surcharge / 100) * subTotal;
    const discount = getValues(`${fieldItem?.attributes?.discountsId}`);
    const tax = getValues(`${fieldItem?.attributes?.taxId}`);
    const taxTotal = (tax / 100) * subTotal;
    return (
      (subTotal ?? 0) -
      (surchargeTotal ?? 0) -
      (taxTotal ?? 0) -
      (discount ?? 0)
    );
  };

  const formatCurrency = (input: string | number) => {
    return `$${Number(input).toFixed(2)}`;
  };

  return (
    <div className="relative w-full">
      <div className="absolute -top-20 right-0">
        <Button
          label="Add Line Item"
          variant="primary"
          className="rounded-full text-white"
          type="button"
          onClick={() => setQuoteItemsModalOpen(true)}
        />
      </div>
      <MultiQuoteItemFieldHeaders />
      <Divider />
      <div className="space-y-5" ref={listRef}>
        {quoteItems.length < 1 && (
          <div className="flex min-h-[200px] flex-col justify-around">
            <div>
              <p className="flex justify-center text-center text-base text-gray-900">
                No line items added, yet
              </p>
              <p className="flex justify-center text-center text-sm text-gray-700">
                To provide an itemized quote,{" "}
                <button
                  type="button"
                  onClick={() => setQuoteItemsModalOpen(true)}
                  className="ml-2 text-theme-green-primary underline"
                >
                  Add Line Items
                </button>
              </p>
            </div>
          </div>
        )}
        {(quoteItems as unknown as MultiQuoteItemValue[]).map(
          (quoteItem, index) => {
            const quoteItemWatch = watch(`${fieldItem?.id}.${index}`);
            const pricebookItem = getPricebookItemById(
              quoteItem.pricebookItemId
            );
            return (
              <div
                key={(quoteItem as any).id}
                onMouseOver={() => throttledMouseEnter(index)}
                onFocus={() => throttledMouseEnter(index)}
                className="relative"
              >
                <Transition
                  show={hoverButtonsTaskIndex === index}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute -left-14 bottom-0 top-0 flex flex-col content-center justify-center space-y-3 transition-all">
                    <button
                      className={classNames(
                        "mx-auto fill-white transition-colors hover:border-black hover:fill-gray-400 hover:text-white",
                        index === 0 && "hidden"
                      )}
                      type="button"
                      onClick={() => {
                        if (index === 0) return;
                        swap(index, index - 1);
                      }}
                    >
                      <ArrowMoveUp />
                    </button>
                    <span className="mx-auto text-gray-500">{index + 1}</span>
                    <button
                      className={classNames(
                        "mx-auto fill-white transition-colors hover:border-black hover:fill-gray-400 hover:text-white",
                        index === quoteItems.length - 1 && "hidden"
                      )}
                      type="button"
                      onClick={() => {
                        if (index === quoteItems.length - 1) return;
                        swap(index, index + 1);
                      }}
                    >
                      <ArrowMoveDown />
                    </button>

                    <button
                      type="button"
                      className=" flex h-7 w-7 items-center justify-center"
                      onClick={() =>
                        insert(index + 1, {
                          pricebookItemId: quoteItem.pricebookItemId,
                          quantity: quoteItem.quantity,
                        })
                      }
                    >
                      <CopyIcon stroke="gray" />
                    </button>
                    <button
                      onClick={() => remove(index)}
                      className="h-7 w-7"
                      type="button"
                    >
                      <TrashIcon fill="gray" />
                    </button>
                  </div>
                </Transition>

                <Divider />
                <div className="grid grid-cols-9 gap-4 py-4">
                  <h4
                    className={classNames(
                      "self-center whitespace-nowrap  font-medium text-theme-black-secondary"
                    )}
                  >
                    {pricebookItem?.name}
                  </h4>

                  <div
                    className={classNames(
                      "col-start-5  self-end  font-medium text-theme-black-secondary"
                    )}
                  >
                    <ControlledInput
                      control={control}
                      name={`${fieldItem.id}.${index}.quantity`}
                      type="number"
                    />
                  </div>
                  <h4
                    className={classNames(
                      "self-center whitespace-nowrap  font-medium text-theme-black-secondary"
                    )}
                  >
                    {pricebookItem?.unit}
                  </h4>
                  <h4
                    className={classNames(
                      "self-center whitespace-nowrap  font-medium text-theme-black-secondary"
                    )}
                  >
                    {pricebookItem?.rate}
                  </h4>
                  <h4
                    className={classNames(
                      "col-start-9  self-center  font-medium text-theme-black-secondary"
                    )}
                  >
                    {getAmount({
                      quoteItem: quoteItemWatch,
                      pricebookItem,
                    })}
                  </h4>
                  <div
                    className={classNames(
                      "col-span-full   self-center  font-medium text-theme-black-secondary"
                    )}
                  >
                    <ControlledTextArea
                      control={control}
                      name={`${fieldItem.id}.${index}.notes`}
                      placeholder="Optional"
                      textareaStyles="min-h-[75px]"
                    />
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      <Divider />
      <div className="my-8 flex flex-col space-y-6">
        <div className="ml-auto flex justify-end space-x-3">
          <h6>Subtotal:</h6>
          <span className="w-40 text-right">
            {formatCurrency(getSubtotal())}
          </span>
        </div>
        <div className="relative ml-auto flex justify-center space-x-3 ">
          <h6 className="my-auto">Discounts:</h6>
          <ControlledInput
            control={control}
            name={`${fieldItem?.attributes?.discountsId}`}
            type="number"
            containerClasses="w-40"
            inputClasses={classNames("text-right")}
          />
          <span className="absolute left-[86px] top-2">$</span>
        </div>
        <div className="relative ml-auto flex space-x-3">
          <h6 className="my-auto">Surcharge:</h6>
          <ControlledInput
            control={control}
            name={`${fieldItem?.attributes?.surchargeId}`}
            containerClasses="w-40 "
            type="number"
            inputClasses={classNames("text-right !pr-6")}
          />
          <span className="absolute right-1 top-[9px]">%</span>
        </div>
        <div className="relative ml-auto flex justify-center space-x-3">
          <h6 className="my-auto">Tax:</h6>
          <ControlledInput
            control={control}
            name={`${fieldItem?.attributes?.taxId}`}
            containerClasses="w-40"
            type="number"
            inputClasses={classNames("text-right !pr-6")}
          />
          <span className="absolute right-1 top-[9px]">%</span>
        </div>
        <div className="relative ml-auto flex justify-center space-x-3">
          <h6 className="my-auto text-lg">Total:</h6>
          <span className="w-40 text-right text-lg">
            {formatCurrency(getTotal())}
          </span>
        </div>
      </div>
      <QuoteItemsModal
        open={quoteItemsModalOpen}
        onCancel={() => setQuoteItemsModalOpen(false)}
        handleAddLineItems={handleAddLineItems}
      />
      <Divider />
    </div>
  );
};
