/* eslint-disable jsx-a11y/label-has-associated-control */
import "./customAutocomplete.css";
import { classNames } from "@/utils/helpers/classNames";
import { FC, useCallback, useEffect, useRef } from "react";
import { JobFormSchema } from "@/utils/formDefinitions/jobManagementService/general";
import { Location } from "@/types/jobManagementService/general";
import { MapIcon } from "@/assets";
import { UseFormSetValue } from "react-hook-form";

type Props = {
  onClickEndAdorment: () => void;
  setValue: UseFormSetValue<JobFormSchema>;
  initialLocation?: Location | null;
};

export const northAmericaBounds = {
  north: 83.113,
  south: 7.197,
  east: -52.619,
  west: -187.1,
};

const CustomAutocomplete: FC<Props> = ({
  setValue,
  onClickEndAdorment,
  initialLocation,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const input = window.rb_map?.inputWrapper1;

  const EndAdornment = useCallback(() => {
    return (
      <button
        className="flex h-10 w-10 items-center justify-center rounded-r-md bg-palette-companyGreen"
        onClick={onClickEndAdorment}
        type="button"
      >
        <MapIcon pathClassName="stroke-white" />
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (input) {
      input.value = initialLocation
        ? initialLocation?.name ?? initialLocation?.formattedAddress
        : "";
      return () => {
        input.value = "";
      };
    }
  }, [initialLocation, input]);

  useEffect(() => {
    let listener: google.maps.MapsEventListener;

    const autocomplete = window.rb_map?.autocomplete_instace_1;

    if (autocomplete && wrapperRef.current && input) {
      input.placeholder = "Select a Location";

      autocomplete.setOptions({
        bounds: { ...northAmericaBounds },
        strictBounds: true,
        fields: ["formatted_address", "geometry"],
      });

      listener = autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (place.geometry?.location) {
          setValue("location", {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            name: place.formatted_address || "",
          });
        }
      });

      wrapperRef.current.appendChild(input);
    }

    return () => {
      listener?.remove();
      if (input) {
        google.maps.event.removeListener(input);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return (
    <div className={classNames("relative col-span-2 flex flex-col")}>
      <label
        htmlFor=""
        className={"text-sm font-medium text-palette-secondaryBlack"}
      >
        Location
      </label>

      <div className="relative">
        <div ref={wrapperRef}></div>

        <div
          className={classNames("absolute inset-y-0 right-0 flex items-center")}
        >
          <EndAdornment />
        </div>
      </div>
    </div>
  );
};

export { CustomAutocomplete };
