import { AssetGroup } from "../../../types/assetInventory/groups/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

export const useAssetGroups = () => {
  const { fetchWithAuth } = useAuthContext();

  return useQuery<AssetGroup, Error>({
    queryKey: [QUERY_KEYS.ASSET_GROUPS],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assetgroups/""`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching asset groups");
      }

      return apiRes.json();
    },
  });
};
