import * as React from "react";
interface EditIconProps {
  className?: string;
}

export const EditIcon: React.FC<EditIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      className={className}
    >
      <path
        d="M13.6 6.15385V3.84615H12V6.15385H9.6V7.69231H12V10H13.6V7.69231H16V6.15385H13.6ZM0 0H10.4V1.53846H0V0ZM0 3.07692H10.4V4.61538H0V3.07692ZM0 6.15385H8V7.69231H0V6.15385Z"
        fill="#151B22"
      />
    </svg>
  );
};
