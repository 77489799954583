import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Button } from "@/UI/Button";
import { Divider } from "@/UI/Divider";
import { EditFormRenderer } from "@/components/Forms/FormEngine/EditFormRenderer";
import { FC, useEffect, useRef, useState } from "react";
import { LoadingSpinner } from "@/UI/Loading";
import { StickyHeader } from "@/UI/StickyHeader";
import { toast } from "react-toastify";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useFormRendererStore } from "@/lib/zustand/formRendererStore";
import { useLocation, useNavigate } from "react-router-dom";
import { EditFormSubmissionBody } from "@/lib/react-query/mutationHooks/useEditFormSubmission";
import { RowField } from "@/types/forms/formEngine";
import { useUser } from "@/lib/react-query/queryHooks/useUser";
import { useCreateFormSubmission } from "@/lib/react-query/mutationHooks/useCreateFormSubmission";

const CreateQuotePage: FC = () => {
  const [isValidFormSubmit, setIsValidFormSubmit] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const { data: accountData } = useAccount({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: user } = useUser({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const formId = searchParams.get("formId");

  const { formObject, setFormObject, clear } = useFormRendererStore(
    (state) => ({
      formObject: state.formObject,
      setFormObject: state.setFormObject,
      clear: state.clear,
    })
  );

  const {
    mutateAsync: createFormSubmission,
    isPending: createFormSubmissionLoading,
  } = useCreateFormSubmission();

  const handleSubmit = async (params: {
    editFormSubmissionBody: EditFormSubmissionBody;
    localDateTime?: string;
  }) => {
    const { editFormSubmissionBody, localDateTime } = params;
    if (!formId) return;
    const body = {
      fields: editFormSubmissionBody.fields,
      localDateTime,
      formId,
      version: formObject.formVersion,
    };
    try {
      const res = await createFormSubmission({ body });
      if (res.data) {
        toast.success("Quote Created.");
        navigate(
          `/quoting/quote-detail?formId=${formId}&formSubmissionId=${res.data.id}`
        );
      }
    } catch (error) {
      toast.error("Unable to create quote.");
    }
  };

  const onSave = () => {
    if (!submitRef.current) return;
    submitRef.current.click();
  };

  useEffect(() => {
    const form = accountData?.data.forms?.find((form: any) => {
      return form.id === formId;
    });
    setFormObject(form);
    return () => {
      if (formObject) {
        clear();
      }
    };
  }, [accountData, formId]);

  const getDefaultValues = () => {
    const defaultValues: Partial<Record<string, string>> = {};
    formObject?.layouts?.web?.fields.map((field: RowField) => {
      if (field.fields) {
        field.fields.map((subField) => {
          if (!subField.id || !subField.attributes?.jobMapping) return;
          switch (subField.attributes.jobMapping) {
            case "userName":
              {
                defaultValues[
                  subField.id
                ] = `${user?.data.firstName} ${user?.data?.lastName}`;
              }
              break;
            case "userPhone":
              {
                defaultValues[subField.id] = user?.data.phoneNumber;
              }
              break;
            case "userEmail":
              {
                defaultValues[subField.id] = user?.data.email;
              }
              break;
            case "branchId":
              {
                if (user?.data?.branchIds && user?.data?.branchIds?.length > 0)
                  defaultValues[subField.id] = user?.data?.branchIds[0];
              }
              break;
            case "divisionId":
              {
                if (
                  user?.data?.divisionIds &&
                  user?.data?.divisionIds?.length > 0
                )
                  defaultValues[subField.id] = user?.data?.divisionIds[0];
              }
              break;
            case "termsAndConditions":
              defaultValues[subField.id] = "true";
              break;
            case "costBreakdown":
              defaultValues[subField.id] = "true";
              break;
            default:
              return;
          }
        });
        return;
      }

      if (!field.id || !field.attributes?.jobMapping) return;
      if (field.attributes?.jobMapping) {
        switch (field.attributes.jobMapping) {
          case "branchId":
            {
              defaultValues[
                field.id
              ] = `${user?.data.firstName} ${user?.data?.lastName}`;
            }
            break;
          default:
            return;
        }
      }
      return;
    });
    return defaultValues;
  };

  return (
    <div className="flex  w-[100%] flex-col py-14">
      <button
        className={`mb-4 flex w-fit items-center gap-x-1 text-theme-black-secondary`}
        onClick={() => navigate("/quoting")}
        type="button"
      >
        <ArrowLeftIcon className="h-6 w-6" />
        <span className="text-xs font-medium">All Quotes</span>
      </button>
      {!formObject && <LoadingSpinner />}
      {formObject && (
        <div className="flex justify-center">
          <section className="max-w-[800px] basis-3/4 rounded-lg">
            <div className="rounded-lg border bg-white">
              <StickyHeader id="workflow-form-header">
                <div className="flex w-full px-14">
                  <div className="flex w-full justify-center">
                    <div className="flex max-w-[800px] basis-3/4 content-center justify-between">
                      <h1 className="my-auto text-3xl font-semibold">
                        {formObject.name} Detail
                      </h1>
                      <div className="my-auto space-x-4 ">
                        <Button
                          onClick={onSave}
                          label="Save"
                          variant="primary"
                          className="rounded-md text-white"
                          disabled={!isValidFormSubmit}
                          loading={createFormSubmissionLoading}
                        />
                      </div>
                    </div>
                    <div className="flex w-full max-w-[347px] basis-1/3" />
                  </div>
                </div>
              </StickyHeader>
              <header className="flex justify-between rounded-t-lg bg-theme-white-secondary p-6">
                <div>
                  <h3 className="text-base text-theme-black-secondary	">New</h3>
                  <h1 className="text-4xl font-semibold">Quote Detail</h1>
                </div>
                <div className="space-x-4 self-end">
                  <Button
                    onClick={onSave}
                    label="Save Changes"
                    variant="primary"
                    className="rounded-md text-white"
                    disabled={!isValidFormSubmit || createFormSubmissionLoading}
                    loading={createFormSubmissionLoading}
                  />
                </div>
              </header>
              <Divider additionalClasses="border-gray-300" />
              <EditFormRenderer
                create
                submitRef={submitRef}
                defaultValues={getDefaultValues()}
                setIsValidFormSubmit={setIsValidFormSubmit}
                handleSubmit={handleSubmit}
              />
            </div>
          </section>

          <aside className="relative max-w-[347px] basis-1/3 space-y-12 px-8"></aside>
        </div>
      )}
    </div>
  );
};

export default CreateQuotePage;
