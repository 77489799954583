import { FormSubmission } from "@/types/forms/general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

export interface EditFormSubmissionBody {
  data?: Record<string, string>;
  fields?: { id: string; value: string }[];
  localDateTime?: string;
  changeNotes?: string;
}
export interface UseEditFormSubmissionMutationParams {
  formId: string;
  submissionId: string;
  body: EditFormSubmissionBody;
}

export const useEditFormSubmission = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    { data: FormSubmission },
    Error,
    UseEditFormSubmissionMutationParams
  >({
    mutationFn: async ({ body, formId, submissionId }) => {
      const editFormSubmissionRes = await fetchWithAuth({
        url: `${timecardsBaseUrl}/forms/${formId}/submission/${submissionId}`,
        method: "PATCH",
        body: JSON.stringify(body),
      });

      if (!editFormSubmissionRes.ok) {
        throw new Error(`Error editing Form Submission`);
      }
      return editFormSubmissionRes.json();
    },
  });
};
