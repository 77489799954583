import { classNames } from "@/utils/helpers/classNames";
import { useLocation, useNavigate } from "react-router-dom";

const AssetsExpandedContent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/assets/browser" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={() => navigate("/assets/browser")}
      >
        <span className="text-sm font-medium">Asset Browser</span>
      </button>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/assets/search" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={() => navigate("/assets/search")}
      >
        <span className="text-sm font-medium">Asset Search</span>
      </button>
    </>
  );
};

export { AssetsExpandedContent };
