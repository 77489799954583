import * as React from "react";

interface IGroupIcon {
  className?: string;
}

export const GroupIcon: React.FC<IGroupIcon> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66923 0.783494C8.74658 0.738835 8.84188 0.738835 8.91923 0.783494L16.7135 5.28349C16.7908 5.32815 16.8385 5.41068 16.8385 5.5V14.5C16.8385 14.5893 16.7908 14.6718 16.7135 14.7165L8.91923 19.2165C8.84188 19.2612 8.74658 19.2612 8.66923 19.2165L0.875 14.7165C0.79765 14.6718 0.75 14.5893 0.75 14.5V5.5C0.75 5.41068 0.79765 5.32815 0.875 5.28349L8.66923 0.783494ZM12.5664 7.38913L15.4635 5.71651L12.1913 3.82733L9.29423 5.49995L12.5664 7.38913ZM12.0664 7.67781L8.79423 5.78863L5.52207 7.67781L8.79423 9.56699L12.0664 7.67781ZM5.02207 7.38913L8.29423 5.49995L5.39715 3.82733L2.125 5.71651L5.02207 7.38913ZM5.89715 3.53865L8.79423 5.21128L11.6913 3.53865L8.79423 1.86603L5.89715 3.53865ZM4.64711 8.03867V11.8169L1.75 10.1443V6.36603L4.64711 8.03867ZM1.75 10.7216V14.067L4.64711 15.7396V12.3943L1.75 10.7216ZM5.14711 16.0283L8.41923 17.9175V14.5721L5.14711 12.683V16.0283ZM8.41923 13.9948L5.14711 12.1056V8.32735L8.41923 10.2165V13.9948ZM9.16923 13.9948V10.2165L12.4413 8.32735V12.1056L9.16923 13.9948ZM9.16923 14.5721V17.9175L12.4413 16.0283V12.683L9.16923 14.5721ZM12.9413 15.7396L15.8385 14.067V10.7216L12.9413 12.3943V15.7396ZM15.8385 10.1443L12.9413 11.8169V8.03867L15.8385 6.36603V10.1443Z"
        fill="black"
      />
    </svg>
  );
};
