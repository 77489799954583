import { z } from "zod";

export const refinedOptionSchema = z
  .object({ id: z.string(), value: z.string() })
  .refine(
    (option) => {
      if (option.id.length < 1 || option.value.length < 1) return false;
      return true;
    },
    { message: "Please select an option." }
  );

export const refinedMultiSelectOptionSchema = z
  .object({ label: z.string(), value: z.string() })
  .refine(
    (option) => {
      if (option.label.length < 1 || option.value.length < 1) return false;
      return true;
    },
    { message: "Please select an option." }
  );

export const OptionSchema = z.object({
  id: z.string(),
  value: z.string(),
});

export type Option = {
  id: string;
  value: string;
};

export const MultiSelectOptionSchema = z.array(
  z.object({
    value: z.string(),
    label: z.string(),
  })
);

export type MultiSelectOption = {
  value: string;
  label: string;
}[];
