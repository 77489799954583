import { AMPLITUDE_EVENTS } from "../helpers/amplitudeEvents";
import { CircledXIcon, OpenBlankIcon } from "@/assets";
import { classNames } from "../helpers/classNames";
import { ColumnDef } from "@tanstack/react-table";
import { DatePicker } from "@/UI/DatePicker";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { format, isAfter } from "date-fns";
import { Input } from "@/UI/Input";
import { isEqual } from "date-fns/esm";
import { JobDocument } from "@/types/jobManagementService/general";
import { Option } from "@/types/option";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { Select } from "@/UI/Select";
import { toast } from "react-toastify";
import { useUpdateJobDocument } from "@/lib/react-query/mutationHooks/useUpdateJobDocument";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  setDeleteModal: Dispatch<
    SetStateAction<{
      document: string;
      name: string;
      open: boolean;
      type: string;
    }>
  >;
};

const VISIBILITY_OPTIONS = [
  { id: "1", value: "Web Only" },
  { id: "2", value: "Web & Mobile" },
];

const NameInput: FC<{
  originalData: JobDocument;
  jobId: string;
}> = ({ originalData, jobId }) => {
  const [name, setName] = useState(originalData.name);

  const timeoutRef = useRef(0);

  const { mutateAsync } = useUpdateJobDocument();

  const handleChange = async (newName: string) => {
    setName(newName);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const queryData = queryClient.getQueryData([
      QUERY_KEYS.JOB_DOCUMENTS,
      { jobId },
    ]) as { data: JobDocument[] };

    const currQueryDataDocIdx = queryData.data.indexOf(originalData);
    const copy = structuredClone(queryData.data);
    copy[currQueryDataDocIdx].name = newName;

    timeoutRef.current = setTimeout(async () => {
      try {
        await mutateAsync({
          body: { ...queryData.data[currQueryDataDocIdx], name: newName },
          documentId: originalData.id,
          jobId: originalData.jobId,
        });

        queryClient.setQueryData([QUERY_KEYS.JOB_DOCUMENTS, { jobId }], {
          data: copy,
        });
      } catch (error) {
        toast.error("Failed to updated document");
      }
    }, 1000);
  };

  return (
    <div className="px-5 py-4">
      <Input
        inputClasses="!text-[15px] !uppercase !font-medium !text-palette-black !bg-[#fbfeff]"
        onChange={(ev) => handleChange(ev.target.value)}
        value={name}
      />
    </div>
  );
};

const ExpirationDatePicker: FC<{
  originalData: JobDocument;
  jobId: string;
}> = ({ originalData, jobId }) => {
  const [expirationDate, setExpirationDate] = useState(originalData.expiresAt);

  const today = new Date().toLocaleDateString();
  const parsedToday = new Date(`${today} 00:00:00`).toISOString().split("T")[0];
  let parsedExpirationDate = "";

  if (expirationDate) {
    parsedExpirationDate = new Date(
      `${new Date(expirationDate).toLocaleDateString()} 00:00:00`
    )
      .toISOString()
      .split("T")[0];
  }

  const timeoutRef = useRef(0);

  const { mutateAsync } = useUpdateJobDocument();

  const handleChange = async (newExpirationDate: string) => {
    setExpirationDate(newExpirationDate);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const queryData = queryClient.getQueryData([
      QUERY_KEYS.JOB_DOCUMENTS,
      { jobId },
    ]) as { data: JobDocument[] };

    const currQueryDataDocIdx = queryData.data.indexOf(originalData);
    const copy = structuredClone(queryData.data);
    copy[currQueryDataDocIdx].expiresAt = newExpirationDate;

    timeoutRef.current = setTimeout(async () => {
      try {
        await mutateAsync({
          body: { ...originalData, expiresAt: newExpirationDate },
          documentId: originalData.id,
          jobId: originalData.jobId,
        });

        queryClient.setQueryData([QUERY_KEYS.JOB_DOCUMENTS, { jobId }], {
          data: copy,
        });
      } catch (error) {
        toast.error("Failed to updated document");
      }
    }, 1000);
  };

  return (
    <div
      className={classNames(
        "px-5 py-4",
        parsedExpirationDate &&
          isEqual(new Date(parsedToday), new Date(parsedExpirationDate))
          ? "bg-red-400"
          : "",
        parsedExpirationDate &&
          isAfter(new Date(parsedExpirationDate), new Date(parsedToday))
          ? "bg-red-400"
          : ""
      )}
    >
      <DatePicker
        customWrapperClassName="dp-job-doc-custom"
        dateFormat="PP"
        onChange={(value) => handleChange(value as string)}
        value={expirationDate}
      />
    </div>
  );
};

const VisibilitySelect: FC<{ originalData: JobDocument; jobId: string }> = ({
  originalData,
  jobId,
}) => {
  const [visibility, setVisibility] = useState(
    originalData.isMobileViewable
      ? VISIBILITY_OPTIONS[1]
      : VISIBILITY_OPTIONS[0]
  );

  const timeoutRef = useRef(0);

  const { mutateAsync } = useUpdateJobDocument();

  const handleChange = async (newVisibility: Option) => {
    setVisibility(newVisibility);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const queryData = queryClient.getQueryData([
      QUERY_KEYS.JOB_DOCUMENTS,
      { jobId },
    ]) as { data: JobDocument[] };

    const currQueryDataDocIdx = queryData.data.indexOf(originalData);
    const copy = structuredClone(queryData.data);
    copy[currQueryDataDocIdx].isMobileViewable =
      newVisibility.id === "1" ? false : true;

    timeoutRef.current = setTimeout(async () => {
      try {
        await mutateAsync({
          body: {
            ...originalData,
            isMobileViewable: newVisibility.id === "1" ? false : true,
          },
          documentId: originalData.id,
          jobId: originalData.jobId,
        });

        queryClient.setQueryData([QUERY_KEYS.JOB_DOCUMENTS, { jobId }], {
          data: copy,
        });
      } catch (error) {
        toast.error("Failed to updated document");
      }
    }, 1000);
  };

  return (
    <div className="px-5 py-4">
      <Select
        hidePlaceholder
        onChange={(option) => {
          if (option) {
            handleChange(option);
          }
        }}
        options={VISIBILITY_OPTIONS}
        value={visibility}
        selectClassNames="!text-[15px] !uppercase !font-medium !text-palette-black !bg-[#fbfeff]"
      />
    </div>
  );
};

export const getJobDocumentsColumnsDefinitions = (
  params: Props
): ColumnDef<JobDocument>[] => {
  const { setDeleteModal } = params;

  return [
    {
      header: "Document Name",
      accessorKey: "name",
      cell: ({
        row: {
          original,
          original: { jobId },
        },
      }) => {
        return <NameInput originalData={original} jobId={jobId} />;
      },
    },
    {
      header: "File Type",
      accessorKey: "contentType",
      cell: ({
        row: {
          original: { contentType },
        },
      }) => {
        return (
          <div className="px-5 py-4">
            <span className="text-[15px] font-medium uppercase text-palette-black">
              {contentType.split("/")[1].toUpperCase()}
            </span>
          </div>
        );
      },
    },
    {
      header: "Uploaded Date",
      accessorKey: "uploadedAt",
      cell: ({
        row: {
          original: { createdAt },
        },
      }) => {
        const formattedDate = format(new Date(createdAt), "PP");
        return (
          <div className="px-5 py-4">
            <span className="text-[15px] font-medium uppercase text-palette-black">
              {formattedDate}
            </span>
          </div>
        );
      },
    },
    {
      header: "Expiration Date",
      accessorKey: "expiresAt",
      cell: ({
        row: {
          original,
          original: { jobId },
        },
      }) => {
        return <ExpirationDatePicker originalData={original} jobId={jobId} />;
      },
      enableSorting: false,
    },
    {
      header: "Visibility",
      accessorKey: "isMobileViewable",
      cell: ({
        row: {
          original,
          original: { jobId },
        },
      }) => {
        return <VisibilitySelect originalData={original} jobId={jobId} />;
      },
      enableSorting: false,
    },
    {
      header: "",
      accessorKey: "actions",
      cell: ({
        row: {
          original: { url, id, name, contentType },
        },
      }) => {
        return (
          <div className="flex gap-x-4 px-5 py-4">
            <button
              onClick={() => {
                window.open(url, "_blank");

                amplitude.track(AMPLITUDE_EVENTS.JOB_DOCUMENT_OPENED, {
                  document_name: name,
                  file_type: contentType,
                });
              }}
            >
              <OpenBlankIcon />
            </button>
            <button
              onClick={() => {
                setDeleteModal({
                  document: id,
                  name,
                  open: true,
                  type: contentType,
                });
              }}
            >
              <CircledXIcon />
            </button>
          </div>
        );
      },
      enableSorting: false,
    },
  ];
};
