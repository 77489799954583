import { z } from "zod";
import { Metadata } from "../metadata";

export const GroupAssetSchema = z.object({
  assetName: z.string(),
  assetId: z.string(),
  quantity: z.string(),
});

export type GroupAsset = z.infer<typeof GroupAssetSchema>;

export type Group = {
  metadata: Metadata[];
  assetGroupName: string;
  assets: GroupAsset[];
  accountId: string;
  assetGroupStatus: string;
  id: string;
};

export type AssetGroup = {
  assetGroups: Group[];
};

export type GetAllAssetGroupsFromAccountParams = {
  accountId: string;
};
