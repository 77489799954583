import { useEffect } from "react";

const useMap = () => {
  useEffect(() => {
    if (!window.rb_map && google) {
      const mapWrapper = document.createElement("div");
      mapWrapper.id = "rb-map-instance";
      mapWrapper.style.width = "100%";
      mapWrapper.style.height = "100%";

      const inputWrapper1 = document.createElement("input");
      inputWrapper1.id = "rb-input-instance-1";

      const inputWrapper2 = document.createElement("input");
      inputWrapper2.id = "rb-input-instance-2";

      const googleMap = new google.maps.Map(mapWrapper);
      const googleMarker = new google.maps.marker.AdvancedMarkerElement();
      const googleAutocomplete1 = new google.maps.places.Autocomplete(
        inputWrapper1
      );
      const googleAutocomplete2 = new google.maps.places.Autocomplete(
        inputWrapper2
      );
      const googleGeocoding = new google.maps.Geocoder();

      window.rb_map = {
        autocomplete_instace_1: googleAutocomplete1,
        autocomplete_instace_2: googleAutocomplete2,
        geocoding_instance: googleGeocoding,
        inputWrapper1,
        inputWrapper2,
        map_instance: googleMap,
        mapWrapper,
        marker_instance: googleMarker,
      };
    }
  }, []);
};

export { useMap };
