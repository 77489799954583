import { capitalizeFirstLetter } from "@/utils/helpers/capitalize";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { CircleIcon } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { Dropdown } from "@/UI/Dropdown";
import { Option } from "@/types/option";
import { Spinner } from "@/UI/Spinner";
import * as React from "react";

export const quoteStatusOptions = [
  { id: "!", value: "Draft" },
  { id: "2", value: "Sent" },
  { id: "3", value: "Accepted" },
  { id: "4", value: "Rejected" },
];
const quoteStatusIconOptions = [
  { id: "!", value: "Draft", icon: <CircleIcon color="#007AFF" /> },
  { id: "2", value: "Sent", icon: <CircleIcon color="#F4A331" /> },
  { id: "3", value: "Accepted", icon: <CircleIcon color="#3CD39C" /> },
  { id: "4", value: "Rejected", icon: <CircleIcon color="#D65D5A" /> },
];
export const statusOptions = [
  { id: "!", value: "New" },
  { id: "2", value: "Issue" },
  { id: "3", value: "Approved" },
  { id: "4", value: "Processed" },
];
const statusIconOptions = [
  { id: "!", value: "New", icon: <CircleIcon color="#007AFF" /> },
  { id: "2", value: "Issue", icon: <CircleIcon color="#F4A331" /> },
  { id: "3", value: "Approved", icon: <CircleIcon color="#3CD39C" /> },
  { id: "4", value: "Processed", icon: <CircleIcon color="#9E00FF" /> },
];

interface StatusSelectorProps {
  type: "form" | "quote";
  status: string | undefined;
  loading?: boolean;
  onChange?: (item: Option) => void;
  buttonClassNames?: string;
}
export const StatusSelector: React.FC<StatusSelectorProps> = ({
  type,
  status,
  loading = false,
  onChange,
  buttonClassNames,
}) => {
  const iconOptions =
    type === "form" ? statusIconOptions : quoteStatusIconOptions;

  const optionIndexSelected = iconOptions.findIndex(
    (statusOption) =>
      statusOption.value.toLocaleLowerCase() === status?.toLocaleLowerCase()
  );
  return (
    <>
      <Dropdown
        buttonElement={
          <>
            {loading ? (
              <div className="flex w-full content-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="flex content-center justify-center">
                  <i className="my-auto mr-2">
                    {iconOptions[optionIndexSelected]?.icon}
                  </i>
                  {status && capitalizeFirstLetter(status)}
                </div>
                <ChevronDownIcon
                  className="ml-auto h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </>
            )}
          </>
        }
        onChange={onChange}
        buttonClassNames={classNames("w-36", buttonClassNames)}
        selectedOption={iconOptions[optionIndexSelected]}
        options={iconOptions}
      />
    </>
  );
};
