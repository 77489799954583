/* eslint-disable */
import { DocumentTypeDecoration } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date custom scalar type */
  Date: { input: any; output: any };
  /** DateTime custom scalar type */
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
};

export type AddressInput = {
  formattedAddress?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lng?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateJobDocumentInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  contentType: Scalars["String"]["input"];
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  isMobileViewable?: InputMaybe<Scalars["Boolean"]["input"]>;
  jobId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  sizeBytes: Scalars["Int"]["input"];
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateJobInput = {
  address?: InputMaybe<AddressInput>;
  assets?: InputMaybe<Array<Scalars["String"]["input"]>>;
  branchId?: InputMaybe<Scalars["String"]["input"]>;
  crew?: InputMaybe<Array<Scalars["String"]["input"]>>;
  customer?: InputMaybe<CustomerInput>;
  divisionId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  name: Scalars["String"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  projectOwner?: InputMaybe<Array<Scalars["String"]["input"]>>;
  salesPerson?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startDate: Scalars["Date"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
  tasks?: InputMaybe<Array<CreateTaskInput>>;
};

export type CreatePriceBookItemInput = {
  accountId?: InputMaybe<Scalars["String"]["input"]>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  branchIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  defaultValue?: InputMaybe<Scalars["Float"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  divisionIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  rate: Scalars["Float"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
  unit: Scalars["String"]["input"];
};

export type CreateTaskInput = {
  assets?: InputMaybe<Array<Scalars["String"]["input"]>>;
  crew?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date: Scalars["Date"]["input"];
};

export type CustomerInput = {
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CustomerTypeEnum {
  Company = "company",
  Individual = "individual",
}

export type DeleteResponse = {
  __typename?: "DeleteResponse";
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars["Int"]["output"];
  /** Human-readable message for the UI */
  message: Scalars["String"]["output"];
  /** Indicates whether the mutation was successful */
  success: Scalars["Boolean"]["output"];
};

export type GqlAddress = {
  __typename?: "GqlAddress";
  formattedAddress?: Maybe<Scalars["String"]["output"]>;
  lat?: Maybe<Scalars["Float"]["output"]>;
  lng?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type GqlAssetSystemCheck = {
  __typename?: "GqlAssetSystemCheck";
  environment: Scalars["JSON"]["output"];
  featureFlags?: Maybe<Scalars["JSON"]["output"]>;
  launchDarklyStatus?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  sqlStatus?: Maybe<Scalars["String"]["output"]>;
};

export type GqlCustomer = {
  __typename?: "GqlCustomer";
  contactName?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<CustomerTypeEnum>;
};

export type GqlJob = {
  __typename?: "GqlJob";
  accountId: Scalars["String"]["output"];
  address?: Maybe<GqlAddress>;
  branchId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  customer?: Maybe<GqlCustomer>;
  divisionId?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  projectOwner?: Maybe<Array<Scalars["String"]["output"]>>;
  salesPerson?: Maybe<Array<Scalars["String"]["output"]>>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<JobStatusEnum>;
  tasks?: Maybe<Array<GqlTask>>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GqlJobDocument = {
  __typename?: "GqlJobDocument";
  accountId: Scalars["String"]["output"];
  active?: Maybe<Scalars["Boolean"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  isMobileViewable?: Maybe<Scalars["Boolean"]["output"]>;
  jobId: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  sizeBytes: Scalars["Int"]["output"];
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  url: Scalars["String"]["output"];
  version?: Maybe<Scalars["String"]["output"]>;
};

export type GqlJobSystemCheck = {
  __typename?: "GqlJobSystemCheck";
  environment: Scalars["JSON"]["output"];
  featureFlags?: Maybe<Scalars["JSON"]["output"]>;
  launchDarklyStatus?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  sqlStatus?: Maybe<Scalars["String"]["output"]>;
};

export type GqlPriceBookItem = {
  __typename?: "GqlPriceBookItem";
  accountId: Scalars["String"]["output"];
  active: Scalars["Boolean"]["output"];
  branchIds?: Maybe<Array<Scalars["String"]["output"]>>;
  category?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  defaultValue?: Maybe<Scalars["Float"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  divisionIds?: Maybe<Array<Scalars["String"]["output"]>>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  rate: Scalars["Float"]["output"];
  status: Scalars["String"]["output"];
  unit: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GqlTask = {
  __typename?: "GqlTask";
  accountId: Scalars["String"]["output"];
  active?: Maybe<Scalars["Boolean"]["output"]>;
  assets?: Maybe<Array<Scalars["String"]["output"]>>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  crew?: Maybe<Array<Scalars["String"]["output"]>>;
  date?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  jobId: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GqlTimecardSystemCheck = {
  __typename?: "GqlTimecardSystemCheck";
  message: Scalars["String"]["output"];
};

export type GqlUserSystemCheck = {
  __typename?: "GqlUserSystemCheck";
  environment: Scalars["JSON"]["output"];
  featureFlags?: Maybe<Scalars["JSON"]["output"]>;
  launchDarklyStatus?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  sqlStatus?: Maybe<Scalars["String"]["output"]>;
};

export type JobDocumentResponse = {
  __typename?: "JobDocumentResponse";
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars["Int"]["output"];
  /** The affected jobDocument object */
  jobDocument?: Maybe<GqlJobDocument>;
  /** Human-readable message for the UI */
  message: Scalars["String"]["output"];
  /** Indicates whether the mutation was successful */
  success: Scalars["Boolean"]["output"];
};

export type JobResponse = {
  __typename?: "JobResponse";
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars["Int"]["output"];
  /** The affected job object */
  job?: Maybe<GqlJob>;
  /** Human-readable message for the UI */
  message: Scalars["String"]["output"];
  /** Indicates whether the mutation was successful */
  success: Scalars["Boolean"]["output"];
};

export enum JobStatusEnum {
  Complete = "complete",
  Ongoing = "ongoing",
  Opportunity = "opportunity",
  Scheduled = "scheduled",
}

export type ListJobDocumentsInput = {
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMobileViewable?: InputMaybe<Scalars["Boolean"]["input"]>;
  jobId: Scalars["String"]["input"];
};

export type ListJobsInput = {
  branchId?: InputMaybe<Scalars["String"]["input"]>;
  divisionId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ListPriceBookItemsInput = {
  branchId?: InputMaybe<Scalars["String"]["input"]>;
  divisionId?: InputMaybe<Scalars["String"]["input"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Creates a new job */
  createJob: JobResponse;
  /** Create a new job document */
  createJobDocument: JobDocumentResponse;
  /** Create a new price book item */
  createPriceBookItem: PriceBookItemResponse;
  /** Delete a job */
  deleteJob: DeleteResponse;
  /** Delete a job document */
  deleteJobDocument: DeleteResponse;
  /** Delete a price book item */
  deletePriceBookItem: DeleteResponse;
  /** Update an existing job */
  updateJob: JobResponse;
  /** Update the dates of a job */
  updateJobDates: JobResponse;
  /** Update an existing job document */
  updateJobDocument: JobDocumentResponse;
};

export type MutationCreateJobArgs = {
  input: CreateJobInput;
};

export type MutationCreateJobDocumentArgs = {
  input: CreateJobDocumentInput;
};

export type MutationCreatePriceBookItemArgs = {
  input: CreatePriceBookItemInput;
};

export type MutationDeleteJobArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteJobDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePriceBookItemArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};

export type MutationUpdateJobDatesArgs = {
  input: UpdateJobDatesInput;
};

export type MutationUpdateJobDocumentArgs = {
  input: UpdateJobDocumentInput;
};

export type PriceBookItemResponse = {
  __typename?: "PriceBookItemResponse";
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars["Int"]["output"];
  /** Human-readable message for the UI */
  message: Scalars["String"]["output"];
  /** The affected jobDocument object */
  priceBookItem?: Maybe<GqlPriceBookItem>;
  /** Indicates whether the mutation was successful */
  success: Scalars["Boolean"]["output"];
};

export type Query = {
  __typename?: "Query";
  assetSystemCheck: GqlAssetSystemCheck;
  job?: Maybe<GqlJob>;
  jobDocuments?: Maybe<Array<Maybe<GqlJobDocument>>>;
  jobSystemCheck: GqlJobSystemCheck;
  jobs?: Maybe<Array<Maybe<GqlJob>>>;
  priceBookItems?: Maybe<Array<Maybe<GqlPriceBookItem>>>;
  task?: Maybe<GqlTask>;
  timecardSystemCheck: GqlTimecardSystemCheck;
  userSystemCheck: GqlUserSystemCheck;
};

export type QueryAssetSystemCheckArgs = {
  input?: InputMaybe<SystemCheckInput>;
};

export type QueryJobArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryJobDocumentsArgs = {
  filters: ListJobDocumentsInput;
};

export type QueryJobSystemCheckArgs = {
  input?: InputMaybe<SystemCheckInput>;
};

export type QueryJobsArgs = {
  filters: ListJobsInput;
};

export type QueryPriceBookItemsArgs = {
  filters: ListPriceBookItemsInput;
};

export type QueryTaskArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUserSystemCheckArgs = {
  input?: InputMaybe<SystemCheckInput>;
};

export type SystemCheckInput = {
  checkLaunchDarkly?: InputMaybe<Scalars["Boolean"]["input"]>;
  checkSQL?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateJobDatesInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  id: Scalars["ID"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type UpdateJobDocumentInput = {
  accountId: Scalars["String"]["input"];
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  isMobileViewable?: InputMaybe<Scalars["Boolean"]["input"]>;
  jobId: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  sizeBytes?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateJobInput = {
  address?: InputMaybe<AddressInput>;
  assets?: InputMaybe<Array<Scalars["String"]["input"]>>;
  branchId?: InputMaybe<Scalars["String"]["input"]>;
  crew?: InputMaybe<Array<Scalars["String"]["input"]>>;
  customer?: InputMaybe<CustomerInput>;
  divisionId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  notes?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  projectOwner?: InputMaybe<Array<Scalars["String"]["input"]>>;
  salesPerson?: InputMaybe<Array<Scalars["String"]["input"]>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreatePriceBookItemMutationVariables = Exact<{
  input: CreatePriceBookItemInput;
}>;

export type CreatePriceBookItemMutation = {
  __typename?: "Mutation";
  createPriceBookItem: {
    __typename?: "PriceBookItemResponse";
    success: boolean;
  };
};

export type DeletePriceBookItemMutationVariables = Exact<{
  deletePriceBookItemId: Scalars["ID"]["input"];
}>;

export type DeletePriceBookItemMutation = {
  __typename?: "Mutation";
  deletePriceBookItem: { __typename?: "DeleteResponse"; success: boolean };
};

export type PriceBookItemsQueryVariables = Exact<{
  filters: ListPriceBookItemsInput;
}>;

export type PriceBookItemsQuery = {
  __typename?: "Query";
  priceBookItems?: Array<{
    __typename?: "GqlPriceBookItem";
    id: string;
    name: string;
    description?: string | null;
    branchIds?: Array<string> | null;
    divisionIds?: Array<string> | null;
    rate: number;
    unit: string;
  } | null> | null;
};

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>["__apiType"];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const CreatePriceBookItemDocument = new TypedDocumentString(`
    mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {
  createPriceBookItem(input: $input) {
    success
  }
}
    `) as unknown as TypedDocumentString<
  CreatePriceBookItemMutation,
  CreatePriceBookItemMutationVariables
>;
export const DeletePriceBookItemDocument = new TypedDocumentString(`
    mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {
  deletePriceBookItem(id: $deletePriceBookItemId) {
    success
  }
}
    `) as unknown as TypedDocumentString<
  DeletePriceBookItemMutation,
  DeletePriceBookItemMutationVariables
>;
export const PriceBookItemsDocument = new TypedDocumentString(`
    query PriceBookItems($filters: ListPriceBookItemsInput!) {
  priceBookItems(filters: $filters) {
    id
    name
    description
    branchIds
    divisionIds
    rate
    unit
  }
}
    `) as unknown as TypedDocumentString<
  PriceBookItemsQuery,
  PriceBookItemsQueryVariables
>;
