import { Job } from "@/types/jobManagementService/general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseEditJobDatesMutationParams {
  props: { startDate: Date; endDate: Date; id: string };
}

export const useEditJobDates = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<Job, Error, UseEditJobDatesMutationParams>({
    mutationFn: async ({ props }) => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/update-job-dates/${props.id}`,
        method: "PATCH",
        body: JSON.stringify(props),
      });

      if (!apiRes.ok) {
        throw new Error(`Error editing Job Dates`);
      }

      return apiRes.json();
    },
  });
};
