import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useUsers } from "@/lib/react-query/queryHooks/useUsers";
import { DynamicPageField, FormSubmission } from "@/types/forms/general";
import { Option } from "@/types/option";
import { get } from "../helpers/lodashCopies";
import { formatDateOnlyTimezone } from "../helpers/dateFormat";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { EditFormSubmissionBody } from "@/lib/react-query/mutationHooks/useEditFormSubmission";
import { AMPLITUDE_EVENTS } from "../helpers/amplitudeEvents";
import * as amplitude from "@amplitude/analytics-browser";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { UseInfiniteFormSubmissionsQueryReturn } from "@/lib/react-query/queryHooks/useInfiniteFormSubmissions";

export type SelectedDocument =
  | (Option & {
      columns?: DynamicPageField[] | undefined;
      filters?: DynamicPageField[] | undefined;
      divisionId?: string;
      bulkDownloads?: ("pdf" | "csv")[];
    })
  | undefined;

type HandleEditFormSubmissionSuccessProps = {
  selectedDocument: SelectedDocument;
  filterStartDate: string | null;
  filterEndDate: string | null;
  submissionId: string;
  body: EditFormSubmissionBody;
  successData: {
    data: FormSubmission;
  };
};
type GetDocumentDataMapProps = {
  data: InfiniteData<UseInfiniteFormSubmissionsQueryReturn>;
  selectedDocument: SelectedDocument;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useDocumentsHelpers = () => {
  const { data: users } = useUsers({ options: { staleTime: 5 * 1000 * 60 } });
  const { data: branches } = useBranches({ options: { staleTime: Infinity } });
  const queryClient = useQueryClient();

  const handleEditFormSubmissionSuccess = ({
    filterEndDate,
    filterStartDate,
    selectedDocument,
    successData,
    submissionId,
    body,
  }: HandleEditFormSubmissionSuccessProps) => {
    if (!selectedDocument?.id) return;
    queryClient.setQueryData(
      [
        QUERY_KEYS.FORM_SUBMISSIONS,
        {
          formId: selectedDocument.id,
          startDate: filterStartDate,
          endDate: filterEndDate,
        },
      ],
      (
        oldData: InfiniteData<UseInfiniteFormSubmissionsQueryReturn> | undefined
      ) => {
        if (!oldData) {
          return { pageParams: [{ nextPageKey: "" }], pages: [] };
        }

        const updatedPages = structuredClone(oldData.pages);
        let loop = true;
        let loopIndex = 0;

        while (loop) {
          const oldFormIndex = updatedPages[loopIndex].data.findIndex(
            (form) => form.id === submissionId
          );

          // if the form was found withing the current page
          if (oldFormIndex >= 0) {
            updatedPages[loopIndex].data.splice(
              oldFormIndex,
              1,
              successData.data
            );

            loop = false;
          }

          // if the form was not found and we reached the end of the pages array
          if (loopIndex + 1 >= updatedPages.length) {
            loop = false;
          }

          loopIndex++;
        }

        return {
          ...oldData,
          pages: updatedPages,
        };
      }
    );
    if (body.data && Object.hasOwn(body.data, "status")) {
      amplitude.track(AMPLITUDE_EVENTS.FORM_STATUS_CHANGED, {
        status_name: body.data?.status,
        form_number: successData.data.data.number,
        form_name: selectedDocument.value,
        page_name: "documents",
      });
      return;
    }
    if (body.data && Object.hasOwn(body.data, "adminNotes")) {
      amplitude.track(AMPLITUDE_EVENTS.FORM_ADMIN_NOTE_ADDED, {
        note: body.data?.adminNotes,
        form_number: successData.data.data.number,
        form_name: selectedDocument.value,
        page_name: "documents",
      });
      return;
    }
  };

  const getDocumentDateMap = ({
    data,
    selectedDocument,
  }: GetDocumentDataMapProps) => {
    const formSubmissionData = data.pages.flatMap((item) => item.data);
    return formSubmissionData?.map(
      (formSubmission: FormSubmission & Record<string, any>) => {
        const mapDynamicFieldsToColumns = (colFilter: DynamicPageField) => {
          // Map StatusSelector, NotesInput, UserField, DateField, BranchField
          // to their proper data in metadata or fields
          if (colFilter.source === "metadata") {
            if (!colFilter?.path) {
              console.error(
                "Error: column filter has source set to 'metadata' but no path property was given."
              );
              return;
            }
            if (colFilter.type === "UserField") {
              formSubmission[colFilter.name] = formSubmission?.userName;
              return;
            }
            if (colFilter.type === "DateField") {
              const dateField = get(formSubmission, colFilter.path ?? "");
              formSubmission[colFilter.name] = formatDateOnlyTimezone(
                dateField,
                // TODO: Remove this default timezone offset. There should ALWAYS be a timezone offset on the form submission. Just alot of bad data right now.
                formSubmission.timezoneOffset ??
                  Intl.DateTimeFormat().resolvedOptions().timeZone
              );
              return;
            }

            if (colFilter.type === "StatusSelector") {
              formSubmission[colFilter.name] =
                formSubmission.data.status ?? "new";
              return;
            }

            formSubmission[colFilter.name] = get(
              formSubmission,
              colFilter.path
            );
            return;
          }

          const fieldValue = formSubmission.fields.find(
            (extraField) => extraField.id === colFilter.id
          )?.value;
          if (colFilter.type === "UserField") {
            if (Array.isArray(fieldValue)) {
              const user = users?.data?.find(
                (user) => user.id === fieldValue[0]?.userId
              );
              if (user?.firstName && user?.lastName) {
                formSubmission[
                  colFilter.name
                ] = `${user?.firstName} ${user?.lastName}`;
              }
              return;
            }
            const user = users?.data?.find((user) => user.id === fieldValue);
            if (user?.firstName && user?.lastName) {
              formSubmission[
                colFilter.name
              ] = `${user?.firstName} ${user?.lastName}`;
              return;
            }
          }
          if (colFilter.type === "MultiUserField") {
            const multiUserFieldIds = Array.isArray(fieldValue)
              ? fieldValue?.flatMap((val) => Object.values(val))
              : [];
            const multiUsers = users?.data?.filter((user) =>
              multiUserFieldIds.includes(user.id)
            );
            const multiUsersNames = multiUsers
              ?.map((user) => {
                if (user?.firstName && user?.lastName)
                  return `${user.firstName} ${user.lastName}`;
                return user.email;
              })
              .join(", ");
            formSubmission[colFilter.name] = multiUsersNames;
            return;
          }
          if (colFilter.type === "MultiBooleanField") {
            const mapValueArray: string[] = [];
            formSubmission.fields.forEach((field) => {
              colFilter?.map?.forEach((map) => {
                if (map.id === field.id && !!field.value)
                  mapValueArray.push(map.name);
              });
            });
            formSubmission[colFilter.name] = mapValueArray.join(", ");
            return;
          }
          if (colFilter.type === "BranchField") {
            const branch = branches?.data?.find(
              (branch) => branch.id === fieldValue
            );
            formSubmission[colFilter.name] = branch?.name;
            return;
          }
          formSubmission[colFilter.name] = fieldValue;
        };

        selectedDocument?.columns?.map(mapDynamicFieldsToColumns);
        selectedDocument?.filters?.map(mapDynamicFieldsToColumns);
        return formSubmission;
      }
    );
  };

  return { getDocumentDateMap, handleEditFormSubmissionSuccess };
};
