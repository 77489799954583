import { ColumnDef, Row } from "@tanstack/react-table";
import { Branch, User } from "../../../types/users/general";
import { Link } from "react-router-dom";
import { fuzzyFilter } from "@/utils/helpers/fuzzyFilter";

type Props = {
  branches: Branch[] | undefined;
  occupations: { name: string; id: string }[];
};

const nameFilter = (row: Row<User>, _id: string, value: string) => {
  const { original } = row;
  const fullName = `${original.firstName} ${original.lastName}`;
  return fullName.toLowerCase().includes(value.toLowerCase());
};

const branchFilter = (
  row: Row<User>,
  _id: string,
  value: string,
  branches: Branch[]
) => {
  const {
    original: { branchIds },
  } = row;

  const branchId = branches.find((branch) => branch.name === value)?.id;

  return Boolean(branchId && branchIds.includes(branchId));
};

export const getOldUsersColumnsDefinitions = ({
  branches = [],
  occupations = [],
}: Props): ColumnDef<User>[] => {
  return [
    {
      header: "User Name",
      accessorKey: "firstName",
      cell: ({
        row: {
          original: { firstName, lastName, id },
        },
      }) => {
        const fullName = `${firstName} ${lastName}`;
        return (
          <Link to={`user-detail?userId=${id}`}>
            <span className="text-blue-700 underline">{fullName}</span>
          </Link>
        );
      },
      enableSorting: false,
      filterFn: nameFilter,
    },

    {
      header: "Position",
      accessorKey: "occupations",
      cell: ({
        row: {
          original: { occupations: userOccupations },
        },
      }) => {
        const occupationsSet = new Set(userOccupations);
        const filterdOccupations = occupations.filter((occupation) =>
          occupationsSet.has(occupation.id)
        );
        const occupationNames = filterdOccupations.map(
          (occupation) => occupation.name
        );
        return occupationNames.join(", ");
      },
    },
    {
      header: "Email",
      accessorKey: "email",
      cell: (info) => info.getValue(),
      filterFn: fuzzyFilter,
    },
    {
      header: "Veteran",
      accessorKey: "veteran",
      cell: ({
        row: {
          original: { veteran },
        },
      }) => <span>{veteran ? "True" : "False"}</span>,
      filterFn: fuzzyFilter,
    },
    {
      header: "Branch",
      accessorKey: "branch",
      cell: ({
        row: {
          original: { branchIds },
        },
      }) => {
        const parsedBranches = branchIds
          ?.flatMap((id) =>
            branches.filter((branch) => branch && branch.id === id)
          )
          .map((branch) => branch.name);

        return <span>{parsedBranches?.join(", ")}</span>;
      },
      filterFn: (row, id, value) => branchFilter(row, id, value, branches),
    },
  ];
};
