import { MouseEvent as ReactMouseEvent } from "react";
import { NavigateFunction } from "react-router-dom";

type Params = {
  event: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>;
  href: string;
  navigate: NavigateFunction;
  pathname: string;
};

export const handleNavigation = (params: Params) => {
  const { event, href, navigate, pathname } = params;
  event.preventDefault();

  const path = `${window.location.origin}/${href}`;

  if (
    pathname === "/map" ||
    pathname === "/jobs/dashboard" ||
    pathname === "/jobs/job-detail"
  ) {
    window.location.reload();
    window.location.assign(path);
  } else {
    navigate(href);
  }
};
