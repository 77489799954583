import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { FormSubmission } from "@/types/forms/general";
import {
  useInfiniteQuery,
  QueryKey,
  InfiniteData,
  UndefinedInitialDataInfiniteOptions,
} from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

export interface UseInfiniteFormSubmissionsQueryReturn {
  data: FormSubmission[];
  nextPageKey: string | null;
}

interface UseInfiniteFormSubmissionsQueryParams {
  startDate: Date | null | undefined | string;
  endDate: Date | null | undefined | string;
  formId: string | undefined;
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<
      UseInfiniteFormSubmissionsQueryReturn,
      Error,
      InfiniteData<UseInfiniteFormSubmissionsQueryReturn>,
      QueryKey,
      { nextPageKey: string }
    >
  >;
}

export const useInfiniteFormSubmissions = (
  params: UseInfiniteFormSubmissionsQueryParams
) => {
  const { fetchWithAuth } = useAuthContext();
  const { formId, endDate, startDate, options } = params;

  return useInfiniteQuery<
    UseInfiniteFormSubmissionsQueryReturn,
    Error,
    InfiniteData<UseInfiniteFormSubmissionsQueryReturn>,
    QueryKey,
    { nextPageKey: string }
  >({
    queryKey: [QUERY_KEYS.FORM_SUBMISSIONS, { formId, startDate, endDate }],
    queryFn: async (params) => {
      const { pageParam } = params;

      const apiRes = await fetchWithAuth({
        url: `${timecardsBaseUrl}/forms/${formId}/submission/list?startDate=${startDate}&endDate=${endDate}&nextPageKey=${pageParam.nextPageKey}&local=true`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching form");
      }

      return apiRes.json();
    },
    initialPageParam: { nextPageKey: "" },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPageKey) {
        return {
          nextPageKey: lastPage.nextPageKey,
        };
      }
    },
    enabled: Boolean(startDate && endDate && formId),
    ...(options && { ...options }),
  });
};
