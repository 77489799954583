import { Job } from "@/types/jobManagementService/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseJobQueryParams {
  jobId?: string;
  options?: Partial<DefinedInitialDataOptions<UseJobQueryReturn>>;
}

export interface UseJobQueryReturn {
  data: Job;
}

export const useJob = (params: UseJobQueryParams) => {
  const { fetchWithAuth } = useAuthContext();
  const { jobId, options } = params;

  return useQuery<UseJobQueryReturn, Error>({
    queryKey: [QUERY_KEYS.JOB, { ...params }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/job/${jobId}`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching job");
      }

      return apiRes.json();
    },
    enabled: Boolean(jobId) && !!jobId?.length,
    ...(options && { ...options }),
  });
};
