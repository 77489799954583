import { ColumnDef, Row } from "@tanstack/react-table";
import { Group } from "@/types/assetInventory/groups/general";

const nameFilter = (row: Row<Group>, _id: string, value: string) => {
  const { original } = row;
  return original.assetGroupName.toLowerCase().includes(value.toLowerCase());
};

export const getWorkflowGroupColumnsDefinitions = (): ColumnDef<Group>[] => {
  return [
    {
      header: "Group",
      accessorKey: "assetGroupName",
      cell: ({
        row: {
          original: { assetGroupName },
        },
      }) => {
        return <span>{assetGroupName}</span>;
      },
      enableSorting: true,
      filterFn: nameFilter,
    },
  ];
};
