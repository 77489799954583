import { GroupAssetForm } from "./GroupAssetsForm";
import { Divider } from "@/UI/Divider";
import { FC, Fragment, useEffect, useState } from "react";
import { Group, GroupAssetSchema } from "@/types/assetInventory/groups/general";
import { ControlledInput } from "@/UI/Input";
import { MetadataForm } from "../../MetadataForm";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { Button } from "@/UI/Button";
import {
  assetGroupFormToCreateQueryMapper,
  assetGroupFormToEditQueryMapper,
  formDefaultValues,
} from "@/utils/formDefinitions/assetInventory/groups/general";
import { Option } from "@/types/option";
import { useEditAssetGroup } from "@/lib/react-query/mutationHooks/useEditAssetGroup";
import { useDeleteAssetGroup } from "@/lib/react-query/mutationHooks/useDeleteAssetGroup";
import { useCreateAssetGroup } from "@/lib/react-query/mutationHooks/useCreateAssetGroup";
import { z } from "zod";
import { MetadataSchema } from "@/types/assetInventory/metadata";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { AssetsAsset } from "@/types/assetInventory/assets/general";

type GroupFormProps = {
  groupData: Group | undefined;
  assetsData: AssetsAsset[] | undefined;
  edit: boolean;
  accountId?: string;
};

const status = [
  {
    id: "0",
    value: "available",
  },
  {
    id: "1",
    value: "unavailable",
  },
];

const createGroupFormSchema = z.object({
  assetGroupName: z.string().min(1, { message: "Required" }),
  assetGroupStatus: z
    .object({
      id: z.string(),
      value: z.string(),
    })
    .refine((val) => Boolean(val.value), { message: "Select a Status" }),
  metadata: z.array(MetadataSchema),
  assets: z.array(GroupAssetSchema),
});

export type GroupFormSchema = z.infer<typeof createGroupFormSchema>;

const GroupForm: FC<GroupFormProps> = ({
  groupData,
  assetsData,
  edit,
  accountId,
}) => {
  const { handleSubmit, reset, control, setValue } = useForm<GroupFormSchema>({
    defaultValues: formDefaultValues,
    resolver: zodResolver(createGroupFormSchema),
  });

  const {
    fields: metadata,
    append: appendMetadata,
    remove: removeMetadata,
  } = useFieldArray({
    control,
    name: "metadata",
  });

  const {
    fields: assets,
    append: appendAssets,
    remove: removeAssets,
  } = useFieldArray({
    control,
    name: "assets",
  });

  const navigate = useNavigate();

  const [assetsSelectOptions, setAssetsSelectOptions] =
    useState<{ id: string; value: string }[]>();

  const createGroupMutation = useCreateAssetGroup();
  const editGroupMutation = useEditAssetGroup();
  const deleteGroupMutation = useDeleteAssetGroup();

  const onSubmit: SubmitHandler<GroupFormSchema> = (data) => {
    if (edit) {
      const editData = assetGroupFormToEditQueryMapper(data);
      if (groupData?.accountId && groupData?.id) {
        editGroupMutation.mutate(
          {
            groupId: groupData.id,
            props: editData,
          },
          {
            onSuccess: () => {
              toast.success("Group Edited");
            },
            onError: (error) => {
              toast.error(error.message);
            },
          }
        );
      }
    } else {
      const createData = assetGroupFormToCreateQueryMapper(data);
      if (accountId) {
        createGroupMutation.mutate(
          {
            props: createData,
          },
          {
            onSuccess: () => {
              toast.success("Group Created");
            },
            onError: (error) => {
              toast.error(error.message);
            },
          }
        );
      }
    }
  };

  const onDelete = () => {
    if (groupData?.accountId && groupData?.id) {
      deleteGroupMutation.mutate(
        {
          groupId: groupData.id,
        },
        {
          onSuccess: () => navigate("/groups", { replace: true }),
          onError: (error) => {
            toast.error(error.message);
          },
        }
      );
    }
  };

  const handleSelect = (option: Option, fielIndex: number) => {
    const selectedAsset = assetsData?.find((asset) => asset.id === option.id);

    if (selectedAsset) {
      setValue(`assets.${fielIndex}.assetName`, selectedAsset.assetName);
      setValue(`assets.${fielIndex}.assetId`, selectedAsset.id);
      setValue(`assets.${fielIndex}.quantity`, "0");
    }
  };

  const addAsset = () => {
    appendAssets({
      assetName: "",
      quantity: "",
      assetId: "",
    });
  };

  const addMetadata = () => {
    appendMetadata({
      metadataName: "",
      metadataUnitOfMeasure: "",
      metadataValue: "",
    });
  };

  useEffect(() => {
    if (groupData) {
      reset({
        ...groupData,
        assetGroupStatus: {
          id:
            groupData.assetGroupStatus.toLowerCase() === "available"
              ? "0"
              : "1",
          value: groupData.assetGroupStatus.toLowerCase(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData]);

  useEffect(() => {
    if (assetsData) {
      const assetsNames = assetsData.map((asset) => ({
        id: asset.id,
        value: asset.assetName,
      }));
      setAssetsSelectOptions(assetsNames);
    }
  }, [assetsData]);

  return (
    <>
      {(groupData || !edit) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-y-10">
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
              <ControlledInput
                control={control}
                name="assetGroupName"
                label="Name"
              />
              <ControlledSelect<GroupFormSchema>
                control={control}
                name="assetGroupStatus"
                options={status}
                capitalize
                label="Status"
              />
            </div>

            {metadata.length ? (
              metadata.map((_meta, idx) => (
                <Fragment key={idx}>
                  <MetadataForm
                    index={idx}
                    control={control}
                    addMetadata={addMetadata}
                    remove={removeMetadata}
                    showAdd={idx + 1 === metadata.length}
                    showRemove
                  />
                </Fragment>
              ))
            ) : (
              <div className="flex items-center justify-center">
                <Button
                  label="Add Metadata"
                  variant="primary"
                  onClick={addMetadata}
                  className="rounded-md"
                />
              </div>
            )}

            <Divider />

            {assets.length ? (
              assets.map((_asset, idx) => (
                <Fragment key={idx}>
                  <GroupAssetForm
                    assetsSelectOptions={
                      assetsSelectOptions ?? [{ id: "", value: "" }]
                    }
                    index={idx}
                    handleSelect={handleSelect}
                    control={control}
                    addAsset={addAsset}
                    remove={removeAssets}
                    showAdd
                    showRemove
                  />
                </Fragment>
              ))
            ) : (
              <div className="flex items-center justify-center">
                <Button
                  label="Add Asset"
                  variant="primary"
                  onClick={addAsset}
                  className="rounded-md"
                />
              </div>
            )}

            <div className="mt-6 flex flex-col justify-between gap-5 md:flex-row">
              {edit && (
                <Button
                  label="Delete Group"
                  variant="tertiary"
                  type="button"
                  onClick={onDelete}
                  className="rounded-md"
                />
              )}
              <div className="flex flex-col gap-5 md:flex-row">
                <Button
                  variant="secondary"
                  label="Cancel"
                  type="button"
                  onClick={() => navigate("/groups", { replace: true })}
                  className="rounded-md"
                />
                <Button
                  variant="primary"
                  label={`${edit ? "Update Group" : "Create Group"}`}
                  type="submit"
                  className="rounded-md"
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export { GroupForm };
