import { FC } from "react";
import { WorkflowTemplates } from "@/components/WorkflowTemplates";

const WorkflowTemplatesPage: FC = () => {
  return (
    <div className="w-[100%] py-14">
      <header className="flex w-full flex-row justify-between">
        <h1 className="text-3xl font-semibold">Workflow Templates</h1>
      </header>
      <WorkflowTemplates />
    </div>
  );
};

export default WorkflowTemplatesPage;
