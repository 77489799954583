/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from "@tanstack/react-table";
import { StatusSelector } from "@/UI/StatusSelector/StatusSelector";
import { EditFormSubmissionBody } from "@/lib/react-query/mutationHooks/useEditFormSubmission";

const multiSelectFilter = (row: any, id: string, value: string) => {
  const rowValueArray: string[] = row.getValue(id)?.split(", ") ?? [];
  return value.split(", ").every((val) => rowValueArray.includes(val));
};

const dateSortingFunction = (
  rowA: any,
  rowB: any,
  columnId: string,
  path?: string
) => {
  if (path) {
    const valueA = new Date(rowA.original[`${path}`]).getTime();
    const valueB = new Date(rowB.original[`${path}`]).getTime();
    return valueA < valueB ? 1 : -1;
  }
  const valueA = new Date(rowA.getValue(columnId)).getTime();
  const valueB = new Date(rowB.getValue(columnId)).getTime();
  return valueA < valueB ? 1 : -1;
};

type LoadingState = {
  submissionId: string | undefined;
  loading: boolean;
  body: EditFormSubmissionBody | undefined;
};

export const getQuotingColumnDefinitions = ({
  editFormSubmissionContext,
  handleEditFormSubmission,
}: {
  editFormSubmissionContext?: LoadingState;
  handleEditFormSubmission: (params: {
    submissionId: string;
    body: EditFormSubmissionBody;
  }) => void;
}): ColumnDef<any>[] => {
  return [
    {
      header: "Job Name",
      accessorKey: "Job Name",
      filterFn: multiSelectFilter,
    },
    {
      header: "Date Created",
      accessorKey: "Date Created",
      filterFn: multiSelectFilter,
      sortingFn: (rowA: any, rowB: any, columnId: string) =>
        dateSortingFunction(rowA, rowB, columnId),
    },
    {
      header: "Created By",
      accessorKey: "Created By",
      filterFn: multiSelectFilter,
    },
    {
      header: "Customer Name",
      accessorKey: "Customer Name",
      filterFn: multiSelectFilter,
    },
    {
      header: "Quote Status",
      accessorKey: "Status",
      filterFn: multiSelectFilter,
      cell: ({
        row: {
          original: { Status, id },
        },
      }) => (
        <StatusSelector
          type="quote"
          status={Status}
          loading={
            editFormSubmissionContext?.submissionId === id &&
            editFormSubmissionContext?.loading &&
            !!Object.hasOwn(
              editFormSubmissionContext.body?.data ?? {},
              "status"
            )
          }
          onChange={(item) => {
            handleEditFormSubmission({
              submissionId: id,
              body: { data: { status: item.value.toLocaleLowerCase() } },
            });
          }}
        />
      ),
    },
  ];
};
