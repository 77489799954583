import { TaskTemplate, WorkflowTemplate } from "@/types/Workflows/general";
import { refinedOptionSchema } from "@/types/option";
import { FieldArrayWithId } from "react-hook-form";
import { z } from "zod";

export const taskTypeOptions = [
  { id: "1", value: "generic" },
  { id: "2", value: "load" },
  { id: "3", value: "transport" },
  { id: "4", value: "form" },
];

export const locationOptions = [
  { id: "1", value: "none" },
  { id: "2", value: "branch" },
  { id: "3", value: "job" },
  // { id: "4", value: "predefined" },
];

export type WorkflowTemplateFormSchema = z.infer<typeof workflowFormSchema>;

export type FieldArrayTasksType = FieldArrayWithId<
  WorkflowTemplateFormSchema,
  "tasks",
  "id"
>[];

export type FieldArrayTaskType = FieldArrayWithId<
  WorkflowTemplateFormSchema,
  "tasks",
  "id"
>;

export type TaskTemplateSchema = z.infer<typeof taskTemplateSchema>;

export const workflowTypeOptions = [
  { id: "1", value: "general" },
  { id: "2", value: "loadout" },
  { id: "3", value: "operate" },
];

export const formTypeOptions = [
  {
    id: "1",
    value: "Inspection",
  },
];

export const genericTaskTypeFields = {
  action: "",
  taskType: taskTypeOptions[0],
  endLocation: locationOptions[0],
  startLocation: locationOptions[0],
};
const defaultOptionsValue = { id: "", value: "" };

export const workflowFormDefaultValues: WorkflowTemplateFormSchema = {
  name: "",
  description: "",
  assetGroupId: undefined,
  workflowType: workflowTypeOptions[0],
  tasks: [],
};
const taskTemplateSchema = z.object({
  taskType: refinedOptionSchema,
  action: z.string().min(1, { message: "Please provide a task name." }),
  id: z.string().optional(),
  notes: z.string().optional(),
  status: z.string().optional(),
  assetCategory: z.array(z.string()).optional(),
  startLocation: refinedOptionSchema.optional(),
  endLocation: refinedOptionSchema.optional(),
  formType: refinedOptionSchema.optional(),
});

export const workflowFormSchema = z.object({
  workflowType: refinedOptionSchema,
  name: z.string().min(1, { message: "Please provide a workflow name." }),
  assetGroupId: z
    .string()
    .min(1, { message: "Please select a group." })
    .optional(),
  description: z.string().min(1, { message: "Please provide a description." }),
  tasks: z.array(taskTemplateSchema),
  id: z.string().uuid().optional(),
});

export const workflowTemplateFormToQueryMapper = (
  workflow: WorkflowTemplateFormSchema
) => {
  return {
    id: workflow.id,
    assetGroupId: workflow.assetGroupId,
    description: workflow.description,
    name: workflow.name,
    workflowType: workflow.workflowType.value,
    taskTemplates: workflow.tasks.map((task) => ({
      action: task.action,
      endLocation: task.endLocation?.value,
      startLocation: task.startLocation?.value,
      notes: task.notes,
      taskType: task.taskType.value,
      formType: task.formType?.value,
      ...(task.id && { id: task.id }),
    })),
  };
};

export const workflowTemplateQueryToFormMapper = (
  workflow: WorkflowTemplate
): WorkflowTemplateFormSchema => {
  return {
    id: workflow.id,
    assetGroupId: workflow.assetGroupId,
    description: workflow.description,
    name: workflow.name,
    workflowType:
      workflowTypeOptions.find(
        (type) => type.value === workflow.workflowType
      ) ?? defaultOptionsValue,
    tasks: workflow.taskTemplates.map((taskTemplate) => {
      return {
        action: taskTemplate.action ?? "",
        taskType:
          taskTypeOptions.find(
            (taskType) => taskType.value === taskTemplate.taskType
          ) ?? defaultOptionsValue,
        endLocation: locationOptions.find(
          (locationOption) => locationOption.value === taskTemplate.endLocation
        ),
        startLocation: locationOptions.find(
          (locationOption) =>
            locationOption.value === taskTemplate.startLocation
        ),
        formType: formTypeOptions.find(
          (formTypeOption) => formTypeOption.value === taskTemplate.formType
        ),
        notes: taskTemplate.notes,
        id: taskTemplate.id,
      };
    }),
  };
};

export const stripExtraTaskTemplateValues = (task: Partial<TaskTemplate>) => {
  return {
    action: task.action,
    endLocation: task.endLocation,
    formType: task.formType,
    id: task.id,
    notes: task.notes,
    startLocation: task.startLocation,
    taskType: task.taskType,
  };
};
