/* eslint-disable @typescript-eslint/no-explicit-any */
import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";
import {
  evaluateExpression,
  getTitleClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";
import { RowField } from "@/types/forms/formEngine";

export interface RowRadioFieldProps {
  fieldItem: RowField;
  value: string;
}
export const RadioField: FC<RowRadioFieldProps> = ({ fieldItem, value }) => {
  const options = fieldItem?.attributes?.radioOptions?.split(",");
  const title = transformTitleByFieldItem(fieldItem);
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);

  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {title && (
        <h4
          className={classNames(
            "py-2 text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {title}
        </h4>
      )}
      {value && (
        <div className="mt-6 max-w-[500px] justify-between space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {options?.map((option, index) => (
            <div key={option + index} className="flex items-center">
              <input
                defaultChecked={value === option}
                value={(value === option) as any}
                id={option}
                name={title}
                type="radio"
                className="h-4 w-4 border-gray-300 text-theme-green-primary focus:ring-0"
              />
              <label
                htmlFor={title}
                className="ml-3 block text-sm font-medium leading-6 text-gray-900"
              >
                {option}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
