import { GroupForm } from "../components/AssetInventory/Groups/GroupForm";
import { useAssetGroup } from "../lib/react-query/queryHooks/useAssetGroup";
import { useAssets } from "../lib/react-query/queryHooks/useAssets";
import { useLocation } from "react-router-dom";

export default function GroupDetailsPage() {
  const { state } = useLocation();
  const groupId = state.id || "";

  const { data: groupData } = useAssetGroup({ groupId });
  const { data: assetsData } = useAssets();

  return (
    <div className="w-full bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-12 mt-24 text-4xl font-semibold">Group</h1>
        <GroupForm groupData={groupData} assetsData={assetsData?.assets} edit />
      </div>
    </div>
  );
}
