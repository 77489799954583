import { JobForm } from "@/components/JobManagement/JobForm";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useJob } from "@/lib/react-query/queryHooks/useJob";
import { useLocation, useNavigate } from "react-router-dom";
import { useUsers } from "@/lib/react-query/queryHooks/useUsers";
import { Loading } from "@/UI/Loading";

export default function JobDetailPage() {
  const navigate = useNavigate();
  const { data: useBranchesData } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: useDivisionsData } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: useUsersData } = useUsers({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("jobId") ?? "";

  const { data: useJobData, isLoading } = useJob({
    jobId,
  });

  if (!jobId) navigate("/schedule");

  if (isLoading) return <Loading />;

  return (
    <div className="w-[916px] bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-6 mt-24 text-4xl font-semibold">Edit Job</h1>
        <h2 className="mb-6 text-lg font-medium">Job Details</h2>
        <JobForm
          branches={useBranchesData?.data}
          divisions={useDivisionsData?.data}
          edit
          job={useJobData?.data}
          users={useUsersData?.data}
        />
      </div>
    </div>
  );
}
