import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserQuerySchema } from "@/types/users/general";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseCreateUserMutationParams {
  props: UserQuerySchema;
}

export const useCreateUser = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, Error, UseCreateUserMutationParams>({
    mutationFn: async ({ props }) => {
      const response = await fetchWithAuth({
        url: `${usersBaseUrl}/users`,
        method: "POST",
        body: JSON.stringify(props),
      });

      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USERS],
        refetchType: "all",
        stale: true,
      });
    },
  });
};
