/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkflowTemplateFilters } from "@/components/WorkflowTemplates";
import { UseInfiniteWorkflowTemplatesQueryParams } from "@/lib/react-query/queryHooks/useInfiniteWorkflowTemplates";

export const mapWorkflowTemplateFiltersToQueryFilters = (
  filters: WorkflowTemplateFilters
): Omit<UseInfiniteWorkflowTemplatesQueryParams, "options"> => {
  const getOrder = () => {
    if (filters.ascending) return "asc";
    if (filters.descending) return "desc";
    return undefined;
  };
  const getSortBy = () => {
    if (filters.creationDate) return "createdAt";
    if (filters.lastModified) return "updatedAt";
    return undefined;
  };
  return {
    assetGroupId: filters.groupId ?? "",
    order: getOrder(),
    search: filters.search,
    sortBy: getSortBy(),
    workflowType: filters.type as any,
  };
};
