import { Option } from "@/types/option";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Modal } from "@/UI/Modal";
import { Select } from "@/UI/Select";
import { Table } from "@/components/Table";
import { GqlPriceBookItem } from "@/lib/graphql/graphql";
import { usePricebookItems } from "@/lib/react-query/queryHooks/usePricebookItems";
import { quoteItemsColumnsDefinition } from "@/utils/columnDefinitions/quoteItemsColumns";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";
import { FC, useState } from "react";
import { Input } from "@/UI/Input";

interface QuoteItemsModalProps {
  open: boolean;
  onCancel: () => void;
  handleAddLineItems: (lineItems: GqlPriceBookItem[]) => void;
}
export const QuoteItemsModal: FC<QuoteItemsModalProps> = ({
  open,
  onCancel,
  handleAddLineItems,
}) => {
  const [localSelectedQuoteItems, setLocalSelectedQuoteItems] = useState<
    GqlPriceBookItem[]
  >([]);
  const [filters, setFilters] = useState<Option[]>();
  const { data: pricebookItems, isPending } = usePricebookItems({
    options: { staleTime: 1000 * 60 * 5 },
  });

  const onRowSelect = (rowSelection: GqlPriceBookItem[]) => {
    setLocalSelectedQuoteItems(rowSelection);
  };

  const onFilter = (value: Option) => {
    const existingFilterIndex = filters?.findIndex(
      (filter) => filter.id === value.id
    );
    if (typeof existingFilterIndex === "number" && existingFilterIndex >= 0) {
      if (value?.value === "1") {
        setFilters((prev) => {
          const mutablePrev = [...prev!];
          mutablePrev[existingFilterIndex] = {
            id: value.id,
            value: undefined as any,
          };
          return mutablePrev;
        });
        return;
      }
      setFilters((prev) => {
        const mutablePrev = [...prev!];
        mutablePrev[existingFilterIndex] = value;
        return mutablePrev;
      });
    } else {
      setFilters((prev) => {
        if (prev) {
          return [...prev, value];
        }
        return [value];
      });
    }
  };

  const { getDivisionOptions, getBranchOptions } = useDocumentDetailHelpers();

  return (
    <Modal
      open={open}
      onConfirm={() => handleAddLineItems(localSelectedQuoteItems)}
      onCancel={onCancel}
      confirmButtonText={
        localSelectedQuoteItems.length
          ? `Add Line Items (${localSelectedQuoteItems.length})`
          : "Select Line Items"
      }
      disableConfirm={!localSelectedQuoteItems.length}
    >
      <header className="mb-4   items-center justify-between gap-20">
        <h1 className="mb-4 text-[33px] font-medium">Add Line Items</h1>

        <div>
          <div className="flex gap-x-6">
            <Select
              options={[
                { id: "1", value: "All Divisions" },
                ...getDivisionOptions(),
              ]}
              className="w-[183px]"
              selectClassNames="!bg-palette-white"
              onChange={(option) => {
                if (option?.value) {
                  onFilter({ id: "division", value: option.id });
                }
              }}
              hidePlaceholder
            />

            <Select
              options={[
                { id: "1", value: "All Branches" },
                ...getBranchOptions(),
              ]}
              className="w-[183px]"
              selectClassNames="!bg-palette-white"
              onChange={(option) => {
                if (option?.value) {
                  onFilter({ id: "branch", value: option.id });
                }
              }}
              hidePlaceholder
            />

            <Input
              containerClasses="ml-auto"
              inputClasses="!w-[283px] placeholder:text-sm  !pl-8 !pr-4 !bg-palette-white"
              placeholder="Search for Crew Member Name"
              startAdornment={<MagnifyingGlassIcon className="h-4 pl-2 " />}
              onChange={({ target: { value } }) => {
                onFilter({ id: "name", value });
              }}
            />
          </div>
        </div>
      </header>

      <div className="h-[500px]  overflow-y-scroll rounded-md border">
        <Table
          enableRowSelection
          emptyMessage="No pricebook items found. Please check your search and filters and try again."
          filters={filters}
          tableColumns={quoteItemsColumnsDefinition({
            selectedPricebookIds: [],
          })}
          tableData={
            (pricebookItems?.data.priceBookItems as GqlPriceBookItem[]) || []
          }
          tdClasses="!p-5"
          loading={isPending}
          thClasses="!uppercase !tracking-[.52] !font-medium !text-[13px] !text-palette-black"
          theadClasses="!bg-[#fafafa]"
          onRowSelectionChanged={onRowSelect}
          visibilityState={{
            branch: false,
            division: false,
          }}
        />
      </div>
    </Modal>
  );
};
