import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

export interface UseCreateFormMutationParams {
  body: {
    name: string;
    formVersion: string;
    divisionId: string;
    fields: {
      name: string;
      attributes: Record<string, string>;
      id: string;
      text: string;
      type: string;
    }[];
  };
}

interface UseCreateFormMutationReturn {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: any;
}

export const useCreateForm = () => {
  const { fetchWithAuth } = useAuthContext();
  return useMutation<
    UseCreateFormMutationReturn,
    Error,
    UseCreateFormMutationParams
  >({
    mutationFn: async ({ body }) => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/accounts/forms`,
        method: "POST",
        body: JSON.stringify(body),
      });

      if (!apiRes.ok) {
        const parsedRes = await apiRes.json();
        if (parsedRes?.error?.description) {
          throw new Error(parsedRes.error.description);
        }
        throw new Error(`Error creating form`);
      }

      return apiRes.json();
    },
  });
};
