/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC } from "react";
import {
  SidebarCalendarIcon,
  NotebookIcon,
  QuadrilateralsIcon,
  SquaresCirclesIcon,
  UserIcon,
  RibbiotIcon,
  MapIcon,
  SettingsIcon,
  SquaresPlusIcon,
  QuoteIcon,
} from "../assets";

export type Nav = {
  name: string;
  icon: FC<any>;
  current: boolean;
  href: string;
  subrefs: string[];
  expandedRoutes: string[];
};

export const extraSidebarRoutes = [
  "/assets/browser",
  "/assets/search",
  "/jobs/dashboard",
  "/jobs/job-detail",
  "/jobs/job-documents",
  "/settings",
  "/settings/branch/create",
  "/settings/division/create",
  "/settings/forms",
  "/settings/forms/create",
  "/settings/forms/edit-csv-config",
  "/settings/forms/edit-dynamic-document",
  "/settings/forms/edit-mobile-layout",
  "/settings/forms/edit-raw-form",
  "/settings/forms/edit-web-layout",
  "/settings/icons",
  "/settings/location",
  "/settings/users",
  "/settings/users/create",
  "/settings/users/user-detail",
  "/users/create",
  "/users/user-detail",
];

export const navBase = [
  {
    name: "Home",
    href: "",
    icon: RibbiotIcon,
    current: false,
    subrefs: [],
    expandedRoutes: [],
  },
];

export const webAssetMapNavigation = [
  {
    name: "Map",
    icon: MapIcon,
    current: false,
    href: "map",
    subrefs: [],
    expandedRoutes: [],
  },
];

export const webScheduleNavigation = [
  {
    name: "Schedule",
    icon: SidebarCalendarIcon,
    current: false,
    href: "schedule",
    subrefs: [
      "/jobs/create",
      "/jobs/job-detail",
      "/jobs/job-documents",
      "/jobs/dashboard",
    ],
    expandedRoutes: [
      "/jobs/job-detail",
      "/jobs/dashboard",
      "/jobs/job-documents",
    ],
  },
];

export const webAssetCrudNavigation = [
  {
    name: "Assets",
    icon: QuadrilateralsIcon,
    current: false,
    href: "assets/browser",
    subrefs: ["/assets/search", "/assets/asset-detail", "/assets/create"],
    expandedRoutes: ["/assets/browser", "/assets/search"],
  },
];

export const webGroupCrudNavigation = [
  {
    name: "Groups",
    icon: SquaresCirclesIcon,
    current: false,
    href: "groups",
    subrefs: [],
    expandedRoutes: [],
  },
];

export const webUserCrudNavigation = [
  {
    name: "Users",
    icon: UserIcon,
    current: false,
    href: "users",
    subrefs: [],
    expandedRoutes: ["/users/create", "/users/user-detail"],
  },
];

export const timecardAdminNavigation = [
  {
    name: "Documents",
    icon: NotebookIcon,
    current: false,
    href: "documents",
    subrefs: [],
    expandedRoutes: [],
  },
];

export const webWorkflowCrudNavigation = [
  {
    name: "Workflow Templates",
    icon: SquaresPlusIcon,
    current: false,
    href: "workflow-templates",
    subrefs: [],
    expandedRoutes: [],
  },
];
export const quotingNavigation = [
  {
    name: "Quoting",
    icon: QuoteIcon,
    current: false,
    href: "quoting",
    subrefs: [],
    expandedRoutes: [],
  },
];

export const settingsNavigation = [
  {
    name: "Settings",
    icon: SettingsIcon,
    current: false,
    href: "settings",
    subrefs: [
      "/settings/branch/create",
      "/settings/division/create",
      "/settings/forms",
      "/settings/forms/edit-csv-config",
      "/settings/forms/edit-dynamic-document",
      "/settings/forms/edit-mobile-layout",
      "/settings/forms/edit-raw-form",
      "/settings/forms/edit-web-layout",
      "/settings/icons",
      "/settings/location",
      "/settings/users",
      "/settings/users/create",
      "/settings/users/user-detail",
      "/settings/pricebook",
    ],
    expandedRoutes: [
      "/settings",
      "/settings/branch/create",
      "/settings/division/create",
      "/settings/forms",
      "/settings/forms/create",
      "/settings/forms/edit-csv-config",
      "/settings/forms/edit-dynamic-document",
      "/settings/forms/edit-mobile-layout",
      "/settings/forms/edit-raw-form",
      "/settings/forms/edit-web-layout",
      "/settings/icons",
      "/settings/location",
      "/settings/users",
      "/settings/users/create",
      "/settings/users/user-detail",
      "/settings/pricebook",
      "/settings/pricebook/create",
    ],
  },
];
