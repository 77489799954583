import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Button } from "@/UI/Button";
import { FilterIcon, MagnifyingGlassIcon } from "@/assets";
import { Flyout } from "@/UI/Flyout";
import { getNewUsersColumnsDefinitions } from "./newUsersColumns";
import { Input } from "@/UI/Input";
import { Option } from "@/types/option";
import { Select } from "@/UI/Select";
import { Table } from "@/components/Table";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUsers } from "@/lib/react-query/queryHooks/useUsers";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  settings?: boolean;
};

const NewUsersPage: FC<Props> = ({ settings = false }) => {
  const [filters, setFilters] = useState<{ id: string; value: string }[]>([]);
  const [branchFilter, setBranchFilter] = useState<Option>();
  const [divisionFilter, setDivisionFilter] = useState<Option>();
  const [occupationFilter, setOccupationFilter] = useState<Option>();

  const { accountId } = useAuthContext();
  const { data: account, isLoading: accountLoading } = useAccount({
    accountId,
  });
  const { data: branches, isLoading: branchesLoading } = useBranches();
  const { data: divisions, isLoading: divisionsLoading } = useDivisions();
  const { data: users, isLoading: usersLoading } = useUsers();
  const navigate = useNavigate();

  const filtersCount = filters.filter((filter) => filter.value);

  const occupationOptions = account?.data.occupations?.map((item) => ({
    id: item.id,
    value: item.name,
  }));

  const branchOptions = branches?.data.map((item) => ({
    id: item.id,
    value: item.name,
  }));

  const divisionOptions = divisions?.data.map((item, idx) => ({
    id: item.id ?? idx.toString(),
    value: item.divisionName ?? "",
  }));

  const permissionBasedUsers = useMemo(() => {
    const enabledUsers = users?.data.filter((user) => !user.disabled);

    if (settings) {
      return enabledUsers;
    }

    const nonRibbiotUsers = enabledUsers?.filter(
      (user) => !user.email?.includes("@ribbiot.com")
    );

    return nonRibbiotUsers;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const handleFiltering = (filterValue: Option, originalOption?: Option) => {
    const clonedFilters = structuredClone(filters);
    const index = clonedFilters.findIndex((item) => item.id === filterValue.id);

    if (index >= 0) {
      clonedFilters[index] = filterValue;
    } else {
      clonedFilters.push(filterValue);
    }

    setFilters(clonedFilters);

    switch (filterValue.id) {
      case "branch":
        setBranchFilter(originalOption);
        break;
      case "division":
        setDivisionFilter(originalOption);
        break;
      case "occupation":
        setOccupationFilter(originalOption);
        break;
      default:
        break;
    }
  };

  const onAddUser = () => {
    if (settings) {
      navigate(`/settings/users/create`);
    } else {
      amplitude.track(AMPLITUDE_EVENTS.USER_CREATION_STARTED);
      navigate(`/users/create`);
    }
  };

  const loadingState =
    accountLoading || branchesLoading || divisionsLoading || usersLoading;

  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-8 flex w-full items-center justify-between">
        <h1 className="text-4xl font-semibold">User Admin</h1>

        <div className="flex gap-x-7">
          <Input
            inputClasses="!w-[283px] !pl-6"
            onChange={(ev) =>
              handleFiltering({ id: "name", value: ev.target.value })
            }
            placeholder="Keyword Search (Name)"
            startAdornment={<MagnifyingGlassIcon />}
          />

          <Flyout
            popoverButton={
              <div
                className="
                flex h-10 w-full items-center
                justify-center gap-x-2 rounded-[50px] bg-theme-green-primary px-10 text-[14px] font-medium text-white 
                hover:opacity-80 active:opacity-80 disabled:bg-theme-black-quinary disabled:text-theme-gray-octonary"
              >
                {<FilterIcon />}
                {`Filters ${
                  filtersCount.length ? `(${filtersCount.length})` : ""
                }`}
              </div>
            }
            content={
              <div className="flex flex-wrap justify-between gap-y-3">
                <Select
                  options={[
                    { id: "", value: "All Branches" },
                    ...(branchOptions ? [...branchOptions] : []),
                  ]}
                  placeholder="Select Branch"
                  onChange={(option) => {
                    if (option) {
                      handleFiltering(
                        { id: "branch", value: option.id },
                        option
                      );
                    }
                  }}
                  label="Branch"
                  className="shrink-0 basis-[49%]"
                  value={branchFilter}
                />

                <Select
                  options={[
                    { id: "", value: "All Divisions" },
                    ...(divisionOptions ? [...divisionOptions] : []),
                  ]}
                  placeholder="Select Division"
                  onChange={(option) => {
                    if (option) {
                      handleFiltering(
                        { id: "division", value: option.id },
                        option
                      );
                    }
                  }}
                  label="Division"
                  className="shrink-0 basis-[49%]"
                  value={divisionFilter}
                />

                <Select
                  options={[
                    { id: "", value: "All Occupations" },
                    ...(occupationOptions ? [...occupationOptions] : []),
                  ]}
                  placeholder="Select Occupation"
                  onChange={(option) => {
                    if (option) {
                      handleFiltering(
                        {
                          id: "occupation",
                          value: option.id,
                        },
                        option
                      );
                    }
                  }}
                  label="Occupation"
                  className="shrink-0 basis-[49%]"
                  value={occupationFilter}
                />
              </div>
            }
          />

          <Button
            className="!rounded-full !text-white"
            label="Add User"
            onClick={onAddUser}
            variant="primary"
          />
        </div>
      </header>

      <div className="h-[calc(100%-72px)] w-full overflow-auto rounded-md border bg-white">
        <Table
          clickableRow
          emptyMessage="No crew members found. Please check your search and filters and try again."
          filters={filters}
          loading={loadingState}
          onRowClick={(row) => {
            if (settings) {
              navigate(`/settings/users/user-detail?userId=${row.original.id}`);
            } else {
              navigate(`/users/user-detail?userId=${row.original.id}`);
            }
          }}
          tableColumns={getNewUsersColumnsDefinitions({
            branches: branchOptions || [],
            divisions: divisionOptions || [],
            occupations: occupationOptions || [],
          })}
          tableData={permissionBasedUsers || []}
          thClasses="!uppercase !tracking-[.52] !font-medium !text-[13px] !text-palette-black"
          theadClasses="!bg-[#fafafa]"
          trClasses="hover:bg-[#e9f2f4]"
        />
      </div>
    </div>
  );
};

export default NewUsersPage;
