import { Button } from "@/UI/Button";
import { ControlledInput } from "@/UI/Input";
import { ControlledMultiSelect } from "@/UI/MultiSelect";
import { ControlledTextArea } from "@/UI/TextArea";
import { CreatePriceBookItemInput } from "@/lib/graphql/graphql";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useCreatePricebookItem } from "@/lib/react-query/mutationHooks/useCreatePricebookItem";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

const pricebookDetailSchema = z.object({
  name: z.string().min(1, { message: "Please provide a Form Name." }),
  description: z
    .string()
    .min(1, { message: "Please provide an item description." }),
  divisionIds: z.array(z.object({ value: z.string(), label: z.string() })),
  branchIds: z.array(z.object({ value: z.string(), label: z.string() })),
  rate: z.number().min(1, { message: "Please provide a positive number" }),
  unit: z.string().min(1, { message: "Please provide a unit" }),
});

type PricebookDetailSchema = z.infer<typeof pricebookDetailSchema>;

const pricebookDetailDefaultValues = {
  name: "My Pricebook item",
  description: "Description for My Pricebook item",
  rate: 100,
  unit: "",
};

interface PricebookItemFormProps {
  divisionsOptions: {
    value: string | undefined;
    label: string | undefined;
  }[];
  branchesOptions: {
    value: string | undefined;
    label: string | undefined;
  }[];
}

export const PricebookItemForm: React.FC<PricebookItemFormProps> = ({
  branchesOptions,
  divisionsOptions,
}) => {
  const { control, handleSubmit, setValue } = useForm<PricebookDetailSchema>({
    defaultValues: pricebookDetailDefaultValues,
    resolver: zodResolver(pricebookDetailSchema),
  });
  const navigate = useNavigate();
  const createPricebookItem = useCreatePricebookItem();
  const onSubmit = (data: PricebookDetailSchema) => {
    const createPricebookItemBody: CreatePriceBookItemInput = {
      name: data.name,
      rate: data.rate,
      unit: data.unit,
      description: data.description,
      branchIds: data.branchIds.map((branch) => branch.value),
      divisionIds: data.divisionIds.map((division) => division.value),
    };
    createPricebookItem.mutate(createPricebookItemBody, {
      onSuccess: async () => {
        toast.success("Pricebook Item Created");
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PRICEBOOK_ITEMS],
        });
        navigate("/settings/pricebook");
      },
      onError: () => {
        toast.error("Error creating pricebook item");
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-4 flex max-w-xl space-x-4">
        <ControlledInput
          control={control}
          name="name"
          label="Item Name"
          containerClasses="!w-full"
        />
      </div>
      <div className="my-4 flex  max-w-xl space-x-4">
        <ControlledTextArea
          control={control}
          name="description"
          label="Form Description"
          containerClassNames="w-full"
          textareaStyles="h-32"
        />
      </div>

      <div className="my-4 flex space-x-4">
        <div className="w-64">
          <ControlledInput
            inputClasses="pl-6"
            startAdornment={<span className="mt-4">$</span>}
            type="number"
            control={control}
            name="rate"
            label="Rate"
            onChange={(e) => setValue("rate", Number(e.target.value))}
          />
        </div>

        <span className="mt-auto h-full -translate-y-2 place-self-center">
          /
        </span>
        <div className="w-64">
          <ControlledInput control={control} name="unit" label="Unit" />
        </div>
      </div>
      <div className="my-4 flex space-x-4">
        <div className="w-64">
          <ControlledMultiSelect<PricebookDetailSchema>
            control={control}
            name="divisionIds"
            label="Divisions"
            options={divisionsOptions}
          />
        </div>
        <div className="w-64">
          <ControlledMultiSelect<PricebookDetailSchema>
            control={control}
            name="branchIds"
            label="Branches"
            options={branchesOptions}
          />
        </div>
      </div>
      <Button
        className="rounded-md"
        type="submit"
        disabled={
          createPricebookItem.isPending || createPricebookItem.isSuccess
        }
        loading={createPricebookItem.isPending}
        label="Create"
        variant="primary"
        labelClasses="text-white"
      />
    </form>
  );
};
