import { Input } from "@/UI/Input";
import { Modal } from "@/UI/Modal";
import { SearchIcon } from "@/assets";
import { Table } from "@/components/Table";
import { useAssetGroups } from "@/lib/react-query/queryHooks/useAssetGroups";
import { Group } from "@/types/assetInventory/groups/general";
import { getWorkflowGroupColumnsDefinitions } from "@/utils/columnDefinitions/workflowGroupColumns";
import { Row } from "@tanstack/react-table";
import * as React from "react";
import { useEffect, useState } from "react";

export interface GroupConfig {
  open: boolean;
  group: Row<Group> | null;
}

interface GroupSelectorModalProps {
  onCancel: () => void;
  onConfirm: (selectedRow: Row<Group> | null) => void;
  groupModalConfig: GroupConfig;
}

export const GroupSelectorModal: React.FC<GroupSelectorModalProps> = ({
  onCancel,
  onConfirm,
  groupModalConfig,
}) => {
  const { data: groupsData, isLoading: groupsLoading } = useAssetGroups();
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedRow, setSelectedRow] = useState<Row<Group> | null>(
    groupModalConfig.group
  );
  useEffect(() => {
    setSelectedRow(groupModalConfig.group);
  }, [groupModalConfig.group]);

  return (
    <Modal
      confirmButtonText="Select Group"
      onCancel={() => {
        onCancel();
        setSelectedRow(groupModalConfig.group);
      }}
      onConfirm={() => {
        onConfirm(selectedRow);
      }}
      open={groupModalConfig.open}
    >
      <section className="flex flex-col gap-y-6 ">
        <header className="flex items-center justify-between">
          <h1 className="ml-4 text-3xl font-semibold text-theme-black-secondary">
            Select Group
          </h1>
          <div className="w-72">
            <Input
              inputClasses="pl-9 text-sm"
              onChange={(ev) => setGlobalFilter(ev.target.value)}
              placeholder="Search"
              startAdornment={<SearchIcon />}
            />
          </div>
        </header>

        <article className="h-[350px] overflow-y-scroll">
          <Table<Group>
            globalFilter={globalFilter}
            clickableRow
            loading={groupsLoading}
            tableColumns={getWorkflowGroupColumnsDefinitions()}
            tableData={groupsData?.assetGroups || []}
            tdClasses="!text-black !text-base !font-medium"
            emptyMessage="No Groups Available"
          />
        </article>
      </section>
    </Modal>
  );
};
