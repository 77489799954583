import { Button } from "../UI/Button";
import { getAssetGroupsColumnsDefinitions } from "../utils/columnDefinitions/assetGroupsColumns";
import { Group } from "../types/assetInventory/groups/general";
import { Loading } from "../UI/Loading";
import { SearchIcon } from "../assets";
import { Table } from "../components/Table";
import { useAssetGroups } from "../lib/react-query/queryHooks/useAssetGroups";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function GroupsPage() {
  const [globalFilter, setGlobalFilter] = useState("");
  const navigate = useNavigate();

  const { data } = useAssetGroups();

  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-5 flex w-full flex-row justify-between">
        <h1 className="text-4xl font-semibold">Groups</h1>
        <div className="flex flex-col gap-4 sm:flex-row">
          <Button
            variant="primary"
            label="Add Group"
            onClick={() => navigate("/groups/create")}
            className="rounded-[50px]"
          />
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="h-10 w-[300px] rounded-md px-9"
              placeholder="Search"
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </header>

      <div className="h-[75vh] w-full overflow-auto rounded-md bg-white">
        {data ? (
          <Table<Group>
            tableData={data.assetGroups || []}
            tableColumns={getAssetGroupsColumnsDefinitions()}
            globalFilter={globalFilter}
            emptyMessage="No Groups Available"
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
