import NewUsersPage from "@/flagged/users/New/NewUsersPage";
import { OldUsersPage } from "@/flagged/users/Old/OldUsersPage";
import { useFlags } from "launchdarkly-react-client-sdk";

const FlaggedUsersPage = () => {
  const { newUsersPage } = useFlags();

  return <>{newUsersPage ? <NewUsersPage /> : <OldUsersPage />}</>;
};

export default FlaggedUsersPage;
