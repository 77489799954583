import { useGoogleMapsAPIKey } from "@/lib/react-query/queryHooks/useGoogleMapsAPIKey";
import { FC, ReactNode } from "react";
import { Wrapper as GoogleWrapper } from "@googlemaps/react-wrapper";
import { useMap } from "@/utils/hooks/useMap";

type MapWrapperProps = {
  children: ReactNode;
};

type WrapperProps = {
  children: ReactNode;
};

const Wrapper: FC<WrapperProps> = ({ children }) => {
  useMap();

  return <>{children}</>;
};

const MapWrapper: FC<MapWrapperProps> = ({ children }) => {
  const { data: apiKey } = useGoogleMapsAPIKey();

  if (!apiKey) return <>{children}</>;

  return (
    <GoogleWrapper
      apiKey={apiKey}
      libraries={["maps", "places", "marker", "geocoding"]}
    >
      <Wrapper>{children}</Wrapper>
    </GoogleWrapper>
  );
};

export { MapWrapper };
