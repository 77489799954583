import { useNotFoundStoreSelectors } from "@/lib/zustand/notFoundStore";
import { useUser } from "@/lib/react-query/queryHooks/useUser";
import NewUserForm from "./NewUserForm";

export const NewUserDetailsPage = () => {
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId") ?? "";
  const setNotFound = useNotFoundStoreSelectors.use.setNotFound();

  if (!userId) {
    setNotFound({ notFound: true, message: "No User Found" });
  }

  const { data: useUserRes, isLoading } = useUser({
    userId,
  });

  return <NewUserForm edit user={useUserRes?.data} loading={isLoading} />;
};
