export enum QUERY_KEYS {
  ACCOUNT = "account",
  ACCOUNTS = "accounts",
  ASSET = "asset",
  ASSET_GROUP = "assetGroup",
  ASSET_GROUPS = "assetGroups",
  ASSET_HISTORY = "assetHistory",
  ASSETS = "assets",
  BASE_CATEGORIES = "baseCategories",
  BRANCH = "branch",
  BRANCHES = "branches",
  BULK_EXPORT_TIMECARDS_PDFS = "bulkExportTimecardsPDFs",
  CATEGORIES = "categories",
  CATEGORY = "category",
  CATEGORY_CHILDREN = "categoryChildren",
  CSV_CONFIG = "csvConfig",
  DIVISION = "division",
  DIVISIONS = "divisions",
  DIVISIONS_FOR_ACCOUNT = "divisionsForAccount",
  DOCUMENT_PDF = "documentPDF",
  EDIT_FORM_SUBMISSION_HISTORY = "editFormSubmissionHistory",
  EXPORT_TIMECARDS_CSV = "exportTimecardsCSV",
  FORM = "form",
  FORM_SUBMISSION = "formSubmission",
  FORM_SUBMISSIONS = "formSubmissions",
  GET_FILTERED_ASSETS = "getFilteredAssets",
  GOOGLE_MAP_KEY = "googleMapKey",
  JOB = "job",
  JOB_DOCUMENTS = "jobDocuments",
  JOBS = "jobs",
  MANUFACTURERS = "manufacturers",
  SCHEDULE_ASSETS_FOR_JOB = "scheduleAssetsForJob",
  USE_FILTERED_ASSETS = "useFilteredAssets",
  USER = "user",
  USERS = "users",
  WORKFLOW = "workflow",
  WORKFLOW_TEMPLATES = "workflowTemplates",
  PRICEBOOK_ITEMS = "pricebookItems",
}

export enum MUTATION_KEYS {
  UPDATE_JOB_DOCUMENT = "updateJobDocument",
}
