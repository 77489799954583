import { QUERY_KEYS } from "../constants";
import {
  InfiniteData,
  QueryKey,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import {
  WorkflowTemplate,
  WorkflowTemplateType,
} from "@/types/Workflows/general";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

type UseInfiniteWorkflowTemplatesReturn = {
  data: WorkflowTemplate[];
  nextPageKey: string | null;
};

export interface UseInfiniteWorkflowTemplatesQueryParams {
  sortBy: "createdAt" | "updatedAt" | "name" | undefined;
  order: "asc" | "desc" | undefined;
  search: string | undefined;
  workflowType: WorkflowTemplateType | undefined;
  assetGroupId: string | undefined;
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<
      UseInfiniteWorkflowTemplatesReturn,
      Error,
      InfiniteData<UseInfiniteWorkflowTemplatesReturn>,
      QueryKey,
      { nextPageKey: string }
    >
  >;
}

export const useInfiniteWorkflowTemplates = (
  params: UseInfiniteWorkflowTemplatesQueryParams
) => {
  const { fetchWithAuth } = useAuthContext();
  const { assetGroupId, order, search, sortBy, workflowType, options } = params;

  return useInfiniteQuery<
    UseInfiniteWorkflowTemplatesReturn,
    Error,
    InfiniteData<UseInfiniteWorkflowTemplatesReturn>,
    QueryKey,
    { nextPageKey: string }
  >({
    queryKey: [
      QUERY_KEYS.WORKFLOW_TEMPLATES,
      { assetGroupId, order, search, sortBy, workflowType },
    ],
    queryFn: async ({
      pageParam = {
        assetGroupId,
        order,
        search,
        sortBy,
        workflowType,
        nextPageKey: "",
      },
    }) => {
      const formattedQueryFilters = Object.entries({
        assetGroupId: assetGroupId,
        order: order,
        search: search,
        sortBy: sortBy,
        workflowType: workflowType,
      })
        .filter((item) => !!item[1])
        .map(([key, value]) => `&${key}=${value}`)
        .join("");

      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/workflow-template?active=true${formattedQueryFilters}&nextPageKey=${pageParam.nextPageKey}`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching workflow templates.");
      }

      return apiRes.json();
    },
    initialPageParam: { nextPageKey: "" },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPageKey) {
        return {
          nextPageKey: lastPage.nextPageKey,
        };
      }
    },
    ...(options && { ...options }),
  });
};
