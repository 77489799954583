import { NewAddUserPage } from "@/flagged/users/New/NewAddUserPage";
import OldAddUserPage from "@/flagged/users/Old/OldAddUserPage";
import { useFlags } from "launchdarkly-react-client-sdk";

const FlaggedAddUserPage = () => {
  const { newUsersPage } = useFlags();

  return <>{newUsersPage ? <NewAddUserPage /> : <OldAddUserPage />}</>;
};

export default FlaggedAddUserPage;
