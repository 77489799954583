import { FC } from "react";

type Props = {
  fill?: string;
};

export const TrashIcon: FC<Props> = ({ fill = "none" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 23" fill={fill}>
      <g filter="url(#filter0_d_63_2705)">
        <path
          d="M12.5 4.66666H9.5C8.58083 4.66666 7.83333 5.41416 7.83333 6.33333V7.16666H4.5V8.83333H6.16667V16.6667C6.16667 17.5858 6.91417 18.3333 7.83333 18.3333H14.1667C15.0858 18.3333 15.8333 17.5858 15.8333 16.6667V8.83333H17.5V7.16666H14.1667V6.33333C14.1667 5.41416 13.4192 4.66666 12.5 4.66666ZM9.5 6.33333H12.5V7.16666H9.5V6.33333ZM14.1667 16.6667H7.83333V8.83333H14.1667V16.6667Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_63_2705"
          x="-3"
          y="-2"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_63_2705"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_63_2705"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
