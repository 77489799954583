import { Control, Path } from "react-hook-form";
import { ControlledInput } from "../../UI/Input";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";

interface MetadataFormProps<T extends object> {
  addMetadata: () => void;
  index: number;
  control: Control<T, object>;
  remove: () => void;
  showAdd: boolean;
  showRemove: boolean;
}

function MetadataForm<T extends object>({
  addMetadata,
  index,
  control,
  remove,
  showAdd,
  showRemove,
}: MetadataFormProps<T>) {
  return (
    <div
      key={index}
      className="grid h-16 grid-cols-[1.125fr_1.125fr_1.125fr_0.3fr] items-center gap-6 border-b border-b-theme-black-tertiary last:border-none"
    >
      <ControlledInput
        control={control}
        name={`metadata.${index}.metadataName` as Path<T>}
        label=""
      />

      <ControlledInput
        control={control}
        name={`metadata.${index}.metadataValue` as Path<T>}
        label=""
      />

      <ControlledInput
        control={control}
        name={`metadata.${index}.metadataUnitOfMeasure` as Path<T>}
        label=""
      />

      <div className="flex items-center justify-end gap-x-4">
        {showAdd && (
          <button
            type="button"
            className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-theme-black-secondary text-theme-black-secondary"
            onClick={addMetadata}
          >
            <PlusIcon className="stroke-[3px]" />
          </button>
        )}
        {showRemove && (
          <button
            type="button"
            className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-theme-black-secondary text-theme-black-secondary"
            onClick={() => remove()}
          >
            <MinusIcon className="stroke-[3px]" />
          </button>
        )}
      </div>
    </div>
  );
}

export { MetadataForm };
