import { QUERY_KEYS } from "../constants";
import { queryClient } from "../general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { GroupAsset } from "@/types/assetInventory/groups/general";
import { Metadata } from "@/types/assetInventory/metadata";
import { useMutation } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface Props {
  assetGroupName: string;
  assetGroupStatus: string;
  assets: GroupAsset[];
  metadata: Metadata[];
}
interface UseEditAssetGroupMutationParams {
  groupId: string;
  props: Props;
}

export const useEditAssetGroup = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<void, Error, UseEditAssetGroupMutationParams>({
    mutationFn: async ({ groupId, props }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assetgroups/""/${groupId}`,
        method: "PUT",
        body: JSON.stringify(props),
      });

      if (!apiRes.ok) {
        throw new Error(`Error editing asset group`);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ASSET_GROUPS],
      });
    },
  });
};
