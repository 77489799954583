import { Button } from "@/UI/Button";
import { Card } from "@/UI/Card";
import { Dropdown } from "@/UI/Dropdown";
import { Input } from "@/UI/Input";
import { Select } from "@/UI/Select";
import { EditIcon, SearchIcon } from "@/assets";
import { GroupIcon } from "@/assets/GroupIcon";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GroupConfig,
  GroupSelectorModal,
} from "../AssetInventory/Groups/GroupSelectorModal";
import { useAssetGroups } from "@/lib/react-query/queryHooks/useAssetGroups";
import { Option } from "@/types/option";
import { mapWorkflowTemplateFiltersToQueryFilters } from "@/utils/helpers/workflowHelpers";
import { workflowTypeOptions } from "@/utils/formDefinitions/workflow/general";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { LoadingSpinner } from "@/UI/Loading";
import { WorkflowTemplate } from "@/types/Workflows/general";
import { useInfiniteWorkflowTemplates } from "@/lib/react-query/queryHooks/useInfiniteWorkflowTemplates";
import { useInfiniteScroll } from "@/utils/hooks/useInfiniteScroll";

export type WorkflowTemplateFilters = {
  search: string | undefined;
  type: string | undefined;
  groupId: string | undefined;
  lastModified: boolean | undefined;
  creationDate: boolean | undefined;
  ascending: boolean | undefined;
  descending: boolean | undefined;
};
const sortOptions = [
  { id: "lastModified", value: "Last Modified" },
  { id: "creationDate", value: "Creation Date" },
  { id: "ascending", value: "Name: A-Z" },
  { id: "descending", value: "Name: Z-A" },
];

const workflowTemplateFiltersBase = {
  search: undefined,
  type: undefined,
  groupId: undefined,
  lastModified: true,
  creationDate: undefined,
  ascending: undefined,
  descending: undefined,
};

export const WorkflowTemplates: FC = () => {
  const navigate = useNavigate();
  const { data: groupsData, isLoading: groupsLoading } = useAssetGroups();

  const [workflowTemplateFilters, setWorkflowTemplateFilters] =
    useState<WorkflowTemplateFilters>(workflowTemplateFiltersBase);

  const [filteredWorkflows, setfilteredWorkflows] = useState<
    WorkflowTemplate[]
  >([]);

  const workflows = useInfiniteWorkflowTemplates({
    ...mapWorkflowTemplateFiltersToQueryFilters(workflowTemplateFilters),
  });

  useInfiniteScroll({
    fetchNextPage: workflows.fetchNextPage,
    hasNextPage: workflows.hasNextPage,
    tableData: filteredWorkflows,
    lastRowSelector: "#last-workflow-template",
  });

  const [searchValue, setSearchValue] = useState("");

  const [groupConfig, setGroupConfig] = useState<GroupConfig>({
    open: false,
    group: null,
  });

  const getGroupById = (id: string) => {
    if (groupsLoading) return "";
    return groupsData?.assetGroups.find((group) => group.id === id)
      ?.assetGroupName;
  };

  useEffect(() => {
    if (workflows.isSuccess) {
      setfilteredWorkflows(
        workflows.data.pages.flatMap((item) => item.data) ?? []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflows.isSuccess]);

  return (
    <article className="min-w-[1000px] overflow-x-auto">
      <section className="my-11 flex w-full justify-between">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center justify-center space-x-4 pl-px">
            <div className="w-96">
              <Input
                startAdornment={
                  <div className="px-1">
                    <SearchIcon />
                  </div>
                }
                inputClasses="pl-9 bg-white"
                placeholder="Search"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setWorkflowTemplateFilters((prev) => ({
                      ...prev,
                      search: searchValue,
                    }));
                  }
                }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <span className="font-light">Filter by:</span>
            <Select
              onChange={(selectedOption) => {
                setWorkflowTemplateFilters((prev) => ({
                  ...prev,
                  type: selectedOption?.value,
                }));
              }}
              options={workflowTypeOptions}
              selectClassNames="border-none"
              placeholder="Workflow Type"
            />
            <button
              onClick={() =>
                setGroupConfig((prev) => ({ ...prev, open: true }))
              }
              className="flex items-center gap-x-1"
            >
              <GroupIcon />
              {groupConfig.group
                ? groupConfig.group.original.assetGroupName
                : "Group"}
            </button>
            <Button
              variant="secondary"
              label="Clear Filters"
              onClick={() => {
                setWorkflowTemplateFilters(workflowTemplateFiltersBase);
                setSearchValue("");
                setGroupConfig({ open: false, group: null });
              }}
              className="!mx-8 !rounded-3xl"
            />
          </div>
          <Button
            variant="primary"
            label="New Template"
            onClick={() => navigate("create")}
            icon={<EditIcon className="h-5 w-5" />}
            className="!rounded-3xl"
          />
        </div>
      </section>
      <div className="flex justify-between">
        <span>Count: {filteredWorkflows.length}</span>
        <div className="flex items-center justify-center space-x-4">
          <span>Sort:</span>
          <Select
            onChange={(selectedOption) => {
              if (!selectedOption?.id) return;
              const exclusionSortOptions = sortOptions
                .filter((sortOption) => sortOption.id !== selectedOption.id)
                .reduce((acc: Record<string, boolean>, curr: Option) => {
                  acc[curr.id] = false;
                  return acc;
                }, {});
              setWorkflowTemplateFilters((prev) => ({
                ...prev,
                [selectedOption?.id]: true,
                ...exclusionSortOptions,
              }));
            }}
            options={sortOptions}
            selectClassNames="border-none"
            containerClassNames="mr-1"
          />
        </div>
      </div>
      <section className="mt-4 space-y-5">
        {(workflows.isLoading || workflows.isFetching) && <LoadingSpinner />}
        {filteredWorkflows.length < 1 && !workflows.isLoading && (
          <p className="text-center">No Workflows matching the filters.</p>
        )}
        {filteredWorkflows?.map((workflow, index) => (
          <Card key={workflow.id} className="h-48">
            <div
              id={
                index === filteredWorkflows.length - 1
                  ? "last-workflow-template"
                  : ""
              }
              className="relative flex h-full items-start gap-5"
            >
              <div className="flex h-full basis-2/3 flex-col justify-evenly space-y-2">
                <p>{workflow.workflowType}</p>
                <h2 className="text-xl font-bold">{workflow.name}</h2>
                {workflow?.assetGroupId && (
                  <p className="flex items-center ">
                    <GroupIcon className="mr-1" />
                    {getGroupById(workflow.assetGroupId)}
                  </p>
                )}
                <p className="max-w-2xl text-sm">
                  {workflow.description.length > 200
                    ? `${workflow.description.substring(0, 200)}...`
                    : workflow.description}
                </p>
              </div>
              <div className="relative z-50 flex h-full basis-1/3 flex-col justify-between">
                <Dropdown
                  menuClassNames="self-end"
                  label="Dropdown List"
                  buttonClassNames="hover:bg-gray-100 bg-white !p-1 !rounded-full"
                  onChange={(item) => {
                    navigate(`workflow-template-detail`, {
                      state: {
                        workflow: workflow,
                        duplicate: item.value === "Duplicate",
                      },
                    });
                  }}
                  options={[
                    { id: "1", value: "Edit" },
                    { id: "2", value: "Duplicate" },
                  ]}
                  buttonElement={
                    <EllipsisHorizontalIcon
                      height={30}
                      width={30}
                      className="rounded-full"
                    />
                  }
                />
                <div className=" self-end">
                  <p className="text-md text-end">
                    {workflow.taskTemplates.length} Tasks
                  </p>
                  <p className="whitespace-nowrap text-end text-sm text-theme-black-secondary">
                    {`Last Modified `}
                    {format(parseISO(workflow.updatedAt), "MMM d, y 'at' p")}
                  </p>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </section>
      <GroupSelectorModal
        onConfirm={(selection) => {
          setGroupConfig({ open: false, group: selection });
          setWorkflowTemplateFilters((prev) => ({
            ...prev,
            groupId: selection?.original.id,
          }));
        }}
        onCancel={() => setGroupConfig((prev) => ({ ...prev, open: false }))}
        groupModalConfig={groupConfig}
      />
    </article>
  );
};
