import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useUser } from "@/lib/react-query/queryHooks/useUser";
import { useAuth0 } from "@auth0/auth0-react";

const ProfilePage = () => {
  const { user } = useAuth0();
  const { data: userData } = useUser();
  const { data: account } = useAccount({
    options: { staleTime: 5 + 1000 * 60 },
  });
  const { data: divisions } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const fullName = `${userData?.data.firstName} ${userData?.data.lastName}`;

  const getDivisionsForUser = () => {
    return divisions?.data
      .filter((division) =>
        userData?.data.divisionIds?.includes(division.id as string)
      )
      .map((division) => division.divisionName)
      .join(", ");
  };

  return (
    <div className="bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-12 mt-24 text-4xl font-semibold">Profile</h1>
        <section className="flex w-full ">
          <img src={user?.picture} alt={user?.nickname} className="mr-10" />
          <div className="space-y-4">
            {userData?.data && (
              <p className="text-3xl font-semibold">{fullName}</p>
            )}
            <div>
              <p>{user?.email}</p>
              {userData?.data.phoneNumber && <p>{userData.data.phoneNumber}</p>}
            </div>
            <div>
              <p>Account: {account?.data.account_name}</p>
              <p>Divisions: {getDivisionsForUser()}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProfilePage;
