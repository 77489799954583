import { ColumnDef } from "@tanstack/react-table";
import { Group } from "../../types/assetInventory/groups/general";
import { Link } from "react-router-dom";

export const getAssetGroupsColumnsDefinitions = (): ColumnDef<Group>[] => {
  return [
    {
      header: "ID",
      accessorKey: "id",
      cell: ({
        row: {
          original: { id },
        },
      }) => (
        <Link to={`group-detail`} state={{ id }}>
          {id}
        </Link>
      ),
    },
    {
      header: "NAME",
      accessorKey: "assetGroupName",
      cell: (info) => info.getValue(),
    },
    {
      header: "STATUS",
      accessorKey: "assetGroupStatus",
      cell: (info) => info.getValue(),
    },
    {
      header: "ACCOUNT",
      accessorKey: "accountId",
      cell: (info) => info.getValue(),
    },
  ];
};
