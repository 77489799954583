import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";
import {
  Assets,
  AssetsAsset,
} from "../../../types/assetInventory/assets/general";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

export interface UseAssetsQueryReturn {
  assets: AssetsAsset[];
  nextPageKey: string | null;
  errors?: { assetId: string; messages: string[] }[];
}

export const useAssets = () => {
  const { fetchWithAuth } = useAuthContext();

  return useQuery<Assets, Error, UseAssetsQueryReturn>({
    queryKey: [QUERY_KEYS.ASSETS],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assets/""`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching assets");
      }

      return apiRes.json();
    },
    gcTime: 720 * (60 * 1000),
    staleTime: 720 * (60 * 1000),
  });
};
