import { GroupForm } from "../components/AssetInventory/Groups/GroupForm";
import { useAssets } from "../lib/react-query/queryHooks/useAssets";

export default function CreateGroupPage() {
  const { data: assetsData } = useAssets();

  return (
    <div className="bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-12 mt-24 text-4xl font-semibold">Group</h1>
        <GroupForm
          groupData={undefined}
          assetsData={assetsData?.assets}
          edit={false}
        />
      </div>
    </div>
  );
}
