/* eslint-disable @typescript-eslint/no-explicit-any */
import { classNames } from "@/utils/helpers/classNames";
import { FC, ReactNode } from "react";
import {
  evaluateExpression,
  getTitleClassesByAttributes,
  getValueClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";
import { RowField } from "@/types/forms/formEngine";

export interface RowFieldProps {
  value?: any;
  fieldItem: RowField;
  endAdornment?: ReactNode;
}
export const Field: FC<RowFieldProps> = ({
  fieldItem,
  value,
  endAdornment,
}) => {
  const title = fieldItem?.text
    ? fieldItem.text
    : transformTitleByFieldItem(fieldItem);

  const getValue = () => {
    if (value && fieldItem?.attributes?.endText) {
      return `${value} ${fieldItem?.attributes?.endText}`;
    }
    return value;
  };

  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);

  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {title && (
        <h4
          className={classNames(
            "text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {title}
        </h4>
      )}
      {getValue() && (
        <p
          className={classNames(
            "flex space-x-2 text-base",
            getValueClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {getValue()}
          {endAdornment}
        </p>
      )}
    </div>
  );
};
