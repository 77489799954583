import "./styles.css";
import { FC } from "react";
import { FieldError } from "react-hook-form";
import { LeftArrow, RightArrow } from "../../assets";
import ReactDatePicker from "react-datepicker";
import { classNames } from "@/utils/helpers/classNames";

export type DatePickerProps = {
  customWrapperClassName?: string;
  dateFormat?: string;
  disabled?: boolean;
  error?: FieldError | undefined;
  externalChangeHandler?: (date: Date | string | null) => void;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  onChange: (date: Date | string | null) => void;
  parseValue?: boolean;
  value: Date | string | null;
};

const DatePicker: FC<DatePickerProps> = ({
  dateFormat = "",
  disabled = false,
  error,
  externalChangeHandler,
  label,
  maxDate,
  minDate,
  onChange,
  parseValue,
  value,
}) => {
  const handleChange = (date: Date | null) => {
    onChange(date ? (parseValue ? date.toString() : date) : null);

    if (externalChangeHandler) {
      externalChangeHandler(
        date ? (parseValue ? date.toString() : date) : null
      );
    }
  };

  const safeParsedValue = value ? new Date(value) : undefined;

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className={classNames(
            "text-sm font-medium",
            disabled ? "text-[#97989e]" : "text-[#575757]"
          )}
          htmlFor={label}
        >
          {label}
        </label>
      )}

      <ReactDatePicker
        {...(dateFormat && { dateFormat })}
        id={label}
        selected={safeParsedValue}
        onChange={handleChange}
        placeholderText="MM/DD/YY"
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        wrapperClassName={`dp${error ? "-error" : ""}`}
        popperClassName="dp"
        calendarClassName="dp"
        renderCustomHeader={({
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          monthDate,
        }) => {
          return (
            <div className="m-3 flex justify-center">
              <div className="flex w-44 justify-between">
                <button
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <LeftArrow />
                </button>

                <div className="flex gap-1">
                  <span className="text-sm font-semibold">
                    {monthDate.toDateString().substring(4, 7)}
                  </span>
                  <span className="text-sm font-semibold">
                    {monthDate.toDateString().substring(11, 15)}
                  </span>
                </div>

                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <RightArrow />
                </button>
              </div>
            </div>
          );
        }}
      />

      {error && (
        <p className="text-[12px] font-medium text-theme-red-primary">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
};

export { DatePicker };
