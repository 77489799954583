/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { FC, useEffect, useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../utils/helpers/classNames";
import { rankItem } from "@tanstack/match-sorter-utils";
import { LoadingSpinner } from "@/UI/Loading";

export interface TableProps<T> {
  clickableRow?: boolean;
  clickedRow?: Row<T> | null;
  containerProps?: string;
  emptyMessage?: string;
  enableRowSelection?: boolean;
  filters?: ColumnFiltersState;
  globalFilter?: string;
  loading?: boolean;
  onRowClick?: (row: Row<T>) => void;
  onRowSelectionChanged?: (selectedAssets: T[]) => void;
  RowEndAdornment?: FC<{ index: number }>;
  tableColumns: ColumnDef<T>[];
  tableContainerCustomId?: string;
  tableData: T[];
  tdClasses?: string;
  thClasses?: string;
  theadClasses?: string;
  thSpanClasses?: string;
  trClasses?: string;
  visibilityState?: Record<string, boolean>;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

function Table<T extends object & { id?: string }>({
  clickableRow,
  clickedRow,
  containerProps = "",
  emptyMessage = "",
  enableRowSelection = false,
  filters,
  globalFilter,
  loading,
  onRowClick,
  onRowSelectionChanged,
  RowEndAdornment,
  tableColumns,
  tableContainerCustomId = "table-container",
  tableData,
  tdClasses = "",
  thClasses = "",
  theadClasses = "",
  thSpanClasses = "",
  trClasses = "",
  visibilityState,
}: TableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    filters || []
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      sorting,
    },
    enableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: fuzzyFilter,
    initialState: { columnVisibility: { ...visibilityState } },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
  });

  const handleRowSelection = (row: Row<T>) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const { rows } = table.getRowModel();

  useEffect(() => {
    table.setColumnVisibility({ ...visibilityState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityState]);

  useEffect(() => {
    if (filters) {
      setColumnFilters(filters);
    }
  }, [filters]);

  useEffect(() => {
    const rowIds = Object.keys(rowSelection);

    if (rowIds?.length) {
      const selectedRows = rowIds.map(
        (rowId) => table.getRow(rowId).original as T
      );

      if (onRowSelectionChanged) {
        onRowSelectionChanged(selectedRows);
      }
    } else {
      if (onRowSelectionChanged) {
        onRowSelectionChanged([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  return (
    <div id={tableContainerCustomId} className={containerProps}>
      <table className="divide-light-gray min-w-full divide-y">
        <thead className={classNames("sticky top-0 bg-white", theadClasses)}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={classNames(
                    "px-5 py-4 text-left text-sm font-semibold text-gray-900",
                    thClasses
                  )}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={classNames(
                        "flex flex-row items-center whitespace-nowrap",
                        `${
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }`
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <span className={classNames(thSpanClasses)}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </span>
                      {{
                        asc: <ChevronUpIcon className="ml-2 inline h-4 w-4" />,
                        desc: (
                          <ChevronDownIcon className="ml-2 inline h-4 w-4" />
                        ),
                      }[header.column.getIsSorted() as string] ??
                        (header.column.getCanSort() && (
                          <MinusIcon className="ml-2 inline h-4 w-4" />
                        ))}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr
                className={classNames(
                  clickableRow ? "cursor-pointer" : "cursor-auto",
                  clickableRow && clickedRow?.original.id === row.original.id
                    ? "rounded-md border-2 border-theme-green-primary bg-theme-green-tertiary"
                    : "border-y border-palette-tertiaryBlack",
                  RowEndAdornment ? "group relative" : "",
                  trClasses
                )}
                key={row?.original.id ?? index}
                onClick={() => handleRowSelection(row)}
              >
                <>
                  {row?.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      <div
                        className={classNames(
                          "whitespace-nowrap px-5 py-4 text-sm text-gray-500",
                          tdClasses
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  ))}
                  {RowEndAdornment && <RowEndAdornment index={index} />}
                </>
              </tr>
            );
          })}
        </tbody>
      </table>

      {!loading && tableData.length < 1 && (
        <p className="flex h-[30vh] items-center justify-center">
          {emptyMessage}
        </p>
      )}

      {!!filters?.length &&
        table?.getRowModel()?.rows?.length < 1 &&
        !loading && (
          <p className="flex h-[30vh] items-center justify-center">
            {emptyMessage}
          </p>
        )}

      {loading && <LoadingSpinner />}
    </div>
  );
}
export { Table };
