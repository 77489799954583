import { FC, ReactNode } from "react";

interface ICard {
  className?: string;
  children: ReactNode;
}

export const Card: FC<ICard> = ({ children, className = "" }) => {
  return (
    <div
      className={`w-full rounded-2xl bg-white px-10 py-6  shadow-lg ${className}`}
    >
      {children}
    </div>
  );
};
