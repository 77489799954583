import { CreateJob, Job } from "@/types/jobManagementService/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseCreateJobMutationParams {
  props: CreateJob;
}

interface MutationReturn {
  data: Job;
}

export const useCreateJob = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<MutationReturn, Error, UseCreateJobMutationParams>({
    mutationFn: async ({ props }): Promise<MutationReturn> => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/job`,
        method: "POST",
        body: JSON.stringify(props),
      });

      if (!apiRes.ok) {
        throw new Error(`Error creating job`);
      }

      return apiRes.json();
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.JOBS] }),
  });
};
