import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { BackArrow } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { handleNavigation } from "@/utils/helpers/handleNavigation";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation, useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const JobsExpandedContent = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { jobDocumentsEnabled } = useFlags();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("jobId") ?? "";

  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start gap-x-2 rounded-md pl-3"
        )}
        onClick={(event) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.JOB, { state }],
          });
          handleNavigation({ event, href: "schedule", navigate, pathname });
        }}
      >
        <BackArrow />

        <span className="text-xs font-medium text-palette-secondaryBlack">
          All Jobs
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/dashboard" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={() => {
          if (pathname !== "/jobs/dashboard") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });
            navigate(`jobs/dashboard?jobId=${jobId}`, { state });
          }
        }}
      >
        <span className="text-sm font-medium">Overview</span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/job-detail" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={() => {
          if (pathname !== "/jobs/job-details") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });
            amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_ENTERED);
            navigate(`jobs/job-detail?jobId=${jobId}`, { state });
          }
        }}
      >
        <span className="text-sm font-medium">Job Details</span>
      </button>

      {jobDocumentsEnabled && (
        <button
          className={classNames(
            "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
            pathname === "/jobs/job-documents"
              ? "bg-palette-secondaryGreen"
              : ""
          )}
          onClick={() => {
            if (pathname !== "/jobs/job-documents") {
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.JOB, { state }],
              });
              amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_ENTERED);
              navigate(`jobs/job-documents?jobId=${jobId}`, { state });
            }
          }}
        >
          <span className="text-sm font-medium">Job Documents</span>
        </button>
      )}
    </>
  );
};

export { JobsExpandedContent };
