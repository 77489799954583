export const MAP_CENTER = {
  lat: 43.195297,
  lng: -101.996554,
};

export const NORTH_AMERICA_BOUNDS = {
  north: 65.12129531234014,
  south: 10.25953808934957,
  west: -176.25394431909305,
  east: -17.63089744409306,
};

export const ASSET_MAP_OPTIONS = {
  center: MAP_CENTER,
  restriction: {
    latLngBounds: NORTH_AMERICA_BOUNDS,
    strictBounds: true,
  },
  zoom: 3,
  mapId: "465a375eb871e19c",
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
};
