import { FC } from "react";
import { MultiQuoteItemValue, RowField } from "@/types/forms/formEngine";
import { Divider } from "@/UI/Divider";
import { classNames } from "@/utils/helpers/classNames";
import { getFormSubmissionValueById } from "@/utils/helpers/forms/formRendererHelpers";
import { usePricebookItems } from "@/lib/react-query/queryHooks/usePricebookItems";
import { GqlPriceBookItem } from "@/lib/graphql/graphql";
import { MultiQuoteItemFieldHeaders } from "./MultiQuoteItemFieldHeaders";
import { LoadingSpinner } from "@/UI/Loading";

type Props = {
  fieldItem: RowField;
  value: any;
};

export const MultiQuoteItemField: FC<Props> = ({ value = [], fieldItem }) => {
  const { data: pricebookItems, isLoading: pricebookItemsLoading } =
    usePricebookItems({
      options: { staleTime: 1000 * 60 * 5 },
    });

  const getPricebookItemById = (id: string) => {
    return pricebookItems?.data?.priceBookItems?.find(
      (item) => item?.id === id
    ) as GqlPriceBookItem;
  };

  const formatCurrency = (input: string | number) => {
    return `$${Number(input).toFixed(2)}`;
  };
  const getAmount = (params: {
    quoteItem: MultiQuoteItemValue;
    pricebookItem: GqlPriceBookItem;
  }) => {
    if (!params.quoteItem.quantity || !params?.pricebookItem?.rate) return 0;
    return formatCurrency(
      Number(params.quoteItem.quantity) * Number(params.pricebookItem.rate)
    );
  };

  const getSubtotal = () => {
    if (!Array.isArray(value)) return 0;
    const sum = value?.reduce((acc: any, quoteItem: any) => {
      const pricebookItem = getPricebookItemById(quoteItem.pricebookItemId);
      return (
        acc +
        Number(quoteItem?.quantity ?? 0) * Number(pricebookItem?.rate ?? 0)
      );
    }, 0);
    return sum as number;
  };

  const getTotal = () => {
    const subTotal = getSubtotal() ?? 0;
    const surcharge =
      getFormSubmissionValueById(`${fieldItem?.attributes?.surchargeId}`) ?? 0;
    const surchargeTotal = (surcharge / 100) * subTotal;
    const discount =
      getFormSubmissionValueById(`${fieldItem?.attributes?.discountsId}`) ?? 0;
    const tax =
      getFormSubmissionValueById(`${fieldItem?.attributes?.taxId}`) ?? 0;
    const taxTotal = (tax / 100) * subTotal;
    return subTotal - surchargeTotal - taxTotal - discount;
  };

  return (
    <>
      <MultiQuoteItemFieldHeaders />
      <Divider />
      {pricebookItemsLoading && (
        <div className="relative min-h-[200px]">
          <LoadingSpinner />
        </div>
      )}
      {(!value || !Array.isArray(value) || value?.length < 1) &&
        !pricebookItemsLoading && (
          <div className="flex min-h-[200px]">
            <p className="my-auto flex w-full  justify-center text-center text-gray-700">
              No line items added, yet
            </p>
          </div>
        )}
      {value &&
        Array.isArray(value) &&
        !pricebookItemsLoading &&
        (value as unknown as MultiQuoteItemValue[]).map((quoteItem) => {
          const pricebookItem = getPricebookItemById(quoteItem.pricebookItemId);
          return (
            <div key={(quoteItem as any).pricebookItemId} className="relative">
              <Divider />
              <div className="grid grid-cols-9 whitespace-nowrap py-4 font-medium text-theme-black-secondary">
                <h4>{pricebookItem?.name}</h4>
                <div className={classNames("col-start-5")}>
                  {quoteItem.quantity}
                </div>
                <h4>
                  <span className="capitalize">{pricebookItem?.unit}</span>
                </h4>
                <h4>{formatCurrency(pricebookItem?.rate)}</h4>
                <h4 className={classNames("col-start-9")}>
                  {getAmount({
                    quoteItem,
                    pricebookItem,
                  })}
                </h4>

                {quoteItem.notes && (
                  <div
                    className={classNames(
                      "col-span-full mt-2  self-center  font-medium text-theme-black-secondary"
                    )}
                  >
                    <h4
                      className={classNames(
                        "text-sm font-medium text-theme-black-secondary"
                      )}
                    >
                      Notes
                    </h4>
                    <span className="w-40">{quoteItem.notes}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      <Divider />
      {Array.isArray(value) && value.length > 0 && !pricebookItemsLoading && (
        <div className="my-8 flex flex-col space-y-6">
          <div className="ml-auto flex justify-end space-x-3">
            <h6>Subtotal:</h6>
            <span className="w-40 text-right">
              {formatCurrency(getSubtotal())}
            </span>
          </div>
          <div className="relative ml-auto flex justify-center space-x-3 ">
            <h6 className="my-auto">Discounts:</h6>
            <p className="w-40 text-right">
              {formatCurrency(
                getFormSubmissionValueById(fieldItem.attributes?.discountsId) ??
                  0
              )}
            </p>
          </div>
          <div className="relative ml-auto flex space-x-3">
            <h6 className="my-auto">
              Surcharge (
              {getFormSubmissionValueById(fieldItem.attributes?.surchargeId) ??
                0}
              %):
            </h6>
            <p className="w-40 text-right">
              {formatCurrency(
                ((getFormSubmissionValueById(
                  fieldItem.attributes?.surchargeId
                ) ?? 0) /
                  100) *
                  getSubtotal()
              )}
            </p>
          </div>
          <div className="relative ml-auto flex justify-center space-x-3">
            <h6 className="my-auto">
              Tax (
              {getFormSubmissionValueById(fieldItem.attributes?.taxId) ?? 0}%):
            </h6>
            <p className="w-40 text-right">
              {formatCurrency(
                ((getFormSubmissionValueById(fieldItem.attributes?.taxId) ??
                  0) /
                  100) *
                  getSubtotal()
              )}
            </p>
          </div>
          <div className="relative ml-auto flex justify-center space-x-3">
            <h6 className="my-auto text-lg">Total:</h6>
            <span className="w-40 text-right text-lg">
              {formatCurrency(getTotal())}
            </span>
          </div>
        </div>
      )}
      <Divider />
    </>
  );
};
