import { Modal } from ".";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Button } from "../Button";
import { FC } from "react";

interface DangerModalProps {
  cancelAction?: () => void;
  cancelButtonDisabled?: boolean;
  confirmAction: () => void;
  confirmButtonDisabled?: boolean;
  confirmLabel?: string;
  message: string;
  open: boolean;
  showCancel?: boolean;
  title: string;
}

const DangerModal: FC<DangerModalProps> = ({
  cancelAction,
  cancelButtonDisabled = false,
  confirmAction,
  confirmButtonDisabled = false,
  confirmLabel = "Confirm",
  message,
  open,
  showCancel = true,
  title,
}) => {
  return (
    <Modal hideButtons innerContainerClasses="!w-[30%]" open={open}>
      <section className="flex flex-col gap-y-5">
        <header className="flex items-center gap-x-5">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
          </div>
          <h1 className="text-2xl font-bold leading-6 text-gray-900">
            {title}
          </h1>
        </header>

        <article>
          <p className="text-lg font-medium">{message}</p>
        </article>

        <footer className="flex w-full items-center justify-end gap-x-5">
          {showCancel && (
            <Button
              className="rounded-md"
              disabled={cancelButtonDisabled}
              label="Cancel"
              onClick={cancelAction}
              type="button"
              variant="secondary"
            />
          )}

          <Button
            className="rounded-md"
            disabled={confirmButtonDisabled}
            label={confirmLabel}
            onClick={confirmAction}
            type="button"
            variant="tertiary"
          />
        </footer>
      </section>
    </Modal>
  );
};

export { DangerModal };
