import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { UserQuerySchema } from "@/types/users/general";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseEditUserMutationParams {
  props: UserQuerySchema;
  userId: string;
}

export const useEditUser = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, Error, UseEditUserMutationParams>({
    mutationFn: async ({ props, userId }) => {
      const editUserRes = await fetchWithAuth({
        url: `${usersBaseUrl}/users/${userId}`,
        method: "PATCH",
        body: JSON.stringify(props),
      });

      if (!editUserRes.ok) {
        throw new Error(`Error editing User`);
      }
    },
    onSuccess: (_data, params) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USERS],
        refetchType: "all",
        stale: true,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER, { userId: params.userId }],
        refetchType: "all",
        stale: true,
      });
    },
  });
};
