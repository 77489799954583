import { Button } from "../../../UI/Button";
import { Flyout } from "../../../UI/Flyout";
import { getOldUsersColumnsDefinitions } from "./oldUsersColumns";
import { LoadingSpinner } from "../../../UI/Loading";
import { Table } from "../../../components/Table";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useNavigate } from "react-router-dom";
import { User } from "../../../types/users/general";
import { UsersFilter, UsersFilterFormSchema } from "@/components/Users";
import { useState } from "react";
import { useUsers } from "../../../lib/react-query/queryHooks/useUsers";

export const OldUsersPage = () => {
  const [usersFiltersFormState, setUsersFiltersFormState] =
    useState<UsersFilterFormSchema>();

  const [filters, setFilters] = useState<{ id: string; value: string }[]>([]);

  const navigate = useNavigate();
  const { accountId } = useAuthContext();

  const { data: account } = useAccount({ accountId });
  const {
    data: users,
    isLoading: usersQueryLoading,
    isFetching: usersQueryFetching,
    isRefetching: usersQueryRefetching,
  } = useUsers();
  const { data: branches } = useBranches();

  const occupations = account ? account.data.occupations : [];

  const emails = users?.data.map((user) => user.email);
  const clearedEmails = Array.from(new Set(emails)).map((item, idx) => ({
    id: idx.toString(),
    value: item,
  }));

  const branchNames = branches?.data.map((branch) => branch.name);
  const clearedBranchNames = Array.from(new Set(branchNames)).map(
    (item, idx) => ({
      id: idx.toString(),
      value: item,
    })
  );

  const names = users?.data.map((user) => `${user.firstName} ${user.lastName}`);
  const clearedNames = Array.from(new Set(names)).map((item, idx) => ({
    id: idx.toString(),
    value: item,
  }));

  const loadingState =
    usersQueryLoading || usersQueryFetching || usersQueryRefetching;

  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-5 flex w-full flex-row justify-between">
        <h1 className="text-4xl font-semibold">Crew Members</h1>
        <div className="flex flex-col gap-4 sm:flex-row">
          <Button
            className="rounded-[50px]"
            label="Create Crew Member"
            variant="primary"
            onClick={() => navigate("create")}
          />
          <Flyout
            popoverButton={
              <div
                className="
                flex h-10 w-full
                items-center justify-center rounded-[50px] bg-theme-green-primary px-10 text-[14px] font-medium text-black 
                hover:opacity-80 active:opacity-80 disabled:bg-theme-black-quinary disabled:text-theme-gray-octonary"
              >
                Filters
              </div>
            }
            content={
              <UsersFilter
                emails={clearedEmails || [{ id: "", value: "" }]}
                ids={clearedBranchNames || [{ id: "", value: "" }]}
                names={clearedNames || [{ id: "", value: "" }]}
                setFilters={setFilters}
                setUsersFiltersFormState={setUsersFiltersFormState}
                usersFiltersFormState={usersFiltersFormState}
              />
            }
          />
        </div>
      </header>

      <div className="h-[75vh] w-full overflow-auto rounded-md bg-white">
        {loadingState ? (
          <LoadingSpinner />
        ) : (
          <Table<User>
            emptyMessage="No users found."
            filters={filters}
            globalFilter={""}
            tableColumns={getOldUsersColumnsDefinitions({
              branches: branches?.data,
              occupations,
            })}
            tableData={
              users?.data
                .filter((user) => !user?.disabled)
                .sort((a, b) =>
                  a.firstName.localeCompare(b.firstName, undefined, {
                    ignorePunctuation: true,
                    numeric: true,
                  })
                ) || []
            }
          />
        )}
      </div>
    </div>
  );
};
