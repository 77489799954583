/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFieldArrayRemove } from "react-hook-form";
import { ControlledInput } from "../../../../UI/Input";
import { ChangeEvent } from "react";
import { Option } from "../../../../types/option";
import { ControlledSelect } from "../../../../UI/Select/ControlledSelect";

interface GroupAssetFormProps {
  addAsset: () => void;
  assetsSelectOptions: { id: string; value: string }[];
  control: any;
  handleSelect: (_option: Option, _fielIndex: number) => void;
  index: number;
  key?: string | number;
  remove: UseFieldArrayRemove;
  showAdd: boolean;
  showRemove: boolean;
}

function GroupAssetForm({
  addAsset,
  assetsSelectOptions,
  control,
  handleSelect,
  index,
  key,
  remove,
  showAdd,
  showRemove,
}: GroupAssetFormProps) {
  const handleSelectChange = (
    option: ChangeEvent<HTMLSelectElement> | Option | null
  ) => {
    handleSelect(option as Option, index);
  };
  return (
    <div className="grid grid-cols-1 gap-5  min-[932px]:grid-cols-4" key={key}>
      <ControlledSelect
        control={control}
        name={`assets.${index}.assetId`}
        options={assetsSelectOptions}
        label="Asset"
        externalHandler={handleSelectChange}
      />

      <ControlledInput
        control={control}
        name={`assets.${index}.assetId`}
        label="Asset ID"
        disabled
      />

      <ControlledInput
        control={control}
        name={`assets.${index}.quantity`}
        label="Quantity"
        disabled
      />

      <div className="flex items-center justify-center gap-x-4">
        {showAdd && (
          <button
            type="button"
            className="flex h-10 w-10 items-center justify-center rounded-full bg-theme-green-primary"
            onClick={addAsset}
          >
            <span className="text-lg font-bold">+</span>
          </button>
        )}
        {showRemove && (
          <button
            type="button"
            className="flex h-10 w-10 items-center justify-center rounded-full border border-theme-green-quinary bg-white font-bold"
            onClick={() => remove(index)}
          >
            <span className="font-bold">-</span>
          </button>
        )}
      </div>
    </div>
  );
}

export { GroupAssetForm };
