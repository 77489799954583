import { NewUserDetailsPage } from "@/flagged/users/New/NewUserDetailsPage";
import OldUserDetailsPage from "@/flagged/users/Old/OldUserDetailsPage";
import { useFlags } from "launchdarkly-react-client-sdk";

const FlaggedUserDetailsPage = () => {
  const { newUsersPage } = useFlags();

  return <>{newUsersPage ? <NewUserDetailsPage /> : <OldUserDetailsPage />}</>;
};

export default FlaggedUserDetailsPage;
